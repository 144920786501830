import { Funcionalidade } from "./funcionalidade";
import { TipoEntidade } from "./tipo-entidade";

export class Perfil {

    id: number;
    nome: string;
    perfilPadrao: boolean;
    acessaAntt: boolean;
    tipos: Array<TipoEntidade>;
    numeroUsuarios: number;
    funcionalidades: Array<Funcionalidade>;

    /**
     *
     */
    constructor(id: number = 0, nome: string = '', perfilPadrao: boolean = false, acessaAntt: boolean = false) {
        this.id = id;
        this.nome = nome;
        this.perfilPadrao = perfilPadrao;
        this.acessaAntt = acessaAntt;
    }
}