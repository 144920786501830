import { Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { NgxRolesService } from 'ngx-permissions';
import { AppInjector } from 'app/app.injector.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivateChild {
    private timeout: any;
    private subscription: Subscription;
    private routeExceptions = ['/portal/home', '/portal/fake', '/login'];
    private permissionService: NgxRolesService;

    constructor(
        private readonly authService: AuthenticationService,
        private readonly router: Router,
        private readonly modalService: ModalService,
    ) {
        const injector = AppInjector.getInjector();
        this.permissionService = injector.get(NgxRolesService);
    }

    private debouce(action: () => any): void {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            action();
        }, 250);
    }

    canActivateChild(privateroute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['login']);
            return false;
        }

        if (this.routeExceptions.find(i => i === state.url)) {
            return true;
        }

        const routes = state.url.replace('/', '').split('/');

        const subRoutes = routes.splice(0, (routes.length - 1));

        if (!this.authService.getRouteAccess(`/${subRoutes.filter(i => isNaN(parseInt(i, 0))).join('/')}`)) {

            this.subscription = this.modalService.event.subscribe((res: any) => {
                this.subscription.unsubscribe();
            });
            this.modalService.info('Você não possui acesso a este recurso.');
            this.router.navigateByUrl('/portal/home');
            return false;

        }
        return true;
    }
}   
