import {
    Directive, OnInit, OnDestroy, Inject, Optional, Host,
    ComponentRef, ViewContainerRef, ComponentFactoryResolver
} from '@angular/core';
import { FORM_ERRORS } from './form-errors';
import { NgControl } from '@angular/forms';
import { ControlErrorComponent } from './control-error.component';
import { Subscription, merge } from 'rxjs';
import { ChangeFormDirective } from './change.directive';
import { ModalService } from 'app/services/modal.service';
import { FormError } from './form-error.model';

// tslint:disable: max-line-length
// tslint:disable: directive-selector
// tslint:disable:no-unused-expression

@Directive({
    selector: '[formControlName]'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
    private focusSubscription: Subscription;
    private changeSubscription: Subscription;
    private ref: ComponentRef<ControlErrorComponent>;
    constructor(
        public vcr: ViewContainerRef,
        public formControl: NgControl,
        @Optional() @Host() public inputChangeDirective: ChangeFormDirective,
        @Inject(FORM_ERRORS) public errors: any,
        public readonly resolver: ComponentFactoryResolver,
        private readonly modalService: ModalService
    ) { }

    ngOnInit() {
        this.changeSubscription =
            merge(
                this.inputChangeDirective.change$,
                this.formControl.valueChanges,
                this.inputChangeDirective.blur$,
                this.inputChangeDirective.focus$
            ).subscribe((item: any) => {
                const controlErrors = this.formControl.errors;
                if (!!controlErrors) {
                    const firstKey = Object.keys(controlErrors)[0];
                    const getError = this.errors[firstKey];
                    const error: FormError = getError(controlErrors[firstKey]);
                    if (
                        controlErrors && !this.formControl.pristine &&
                        (!!error && !!error.beforeValidation)
                    ) {
                        if ((this.formControl.value.length === error.beforeValidation)) {
                            this.setError(error.description);
                        } else if (item instanceof FocusEvent) {
                            this.setError(error.description);
                        } else {
                            this.formControl.control.markAsUntouched();
                            this.setError(null);
                        }
                    } else if (!this.formControl.pristine && !error.beforeValidation && (!this.formControl.value || this.formControl.value.length === 0)) {
                        this.setError(error.description);
                    } else {
                        if (item instanceof FocusEvent && item.type === 'blur') {
                            this.setError(error.description);
                        } else {
                            this.setError(null);
                        }
                    }
                } else {
                    this.setError(null);
                }
            });
    }

    setError(text: string) {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.vcr.createComponent(factory);
        }
        this.ref.instance.text = text;
        this.formControl.control.markAsDirty();
    }

    ngOnDestroy() {
        !!this.focusSubscription ? this.focusSubscription.unsubscribe() : null;
        !!this.changeSubscription ? this.changeSubscription.unsubscribe() : null;
    }
}
