import { Injectable } from '@angular/core';
import { URL_HELP_API } from 'environments/environment';
import { VeiculoBase } from 'app/models/veiculo/veiculo-base';
import { ModalService } from './modal.service';
import { IsLoadingService } from './is-loading.service';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ResponseBase } from 'app/interfaces/response-base';
import { VeiculoPedido } from 'app/models/veiculo/veiculo-pedido';
import { AtualizacaoVeiculoPedidoRequest } from 'app/models/veiculo/atualizacao-veiculo-request';
import { of } from 'rxjs';
import { CriacaoVeiculoRequest } from 'app/models/veiculo/criacao-veiculo-request';

@Injectable({
    providedIn: 'root'
})
export class PedidoVeiculoService extends BaseService<any> {

    constructor(
        protected httpClient: HttpClient,
        protected modalService: ModalService,
        protected isLoadingService: IsLoadingService
    ) {
        super(httpClient, modalService, isLoadingService);
    }

    listar(codigoPedido: number): Promise<ResponseBase<VeiculoPedido>>{
        return new Promise<ResponseBase<VeiculoPedido>>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculos`
            ).subscribe(
                (result: ResponseBase<VeiculoPedido>) => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    listarVeiculosCobranca(codigoPedido: number): Promise<ResponseBase<Array<VeiculoBase>>> {
        return new Promise<ResponseBase<Array<VeiculoBase>>>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculos-base`
            ).subscribe(
                (result: ResponseBase<Array<VeiculoBase>>) => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    listarVeiculosCobrancaCustom(codigoPedido: number): Promise<ResponseBase<Array<VeiculoBase>>> {
        return new Promise<ResponseBase<Array<VeiculoBase>>>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculos-base-com-controle`
            ).subscribe(
                (result: ResponseBase<Array<VeiculoBase>>) => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    detalhe(codigoPedido: number, placa: string) {
        return new Promise<ResponseBase<VeiculoPedido>>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculo/${placa}`
            ).subscribe(
                (result: ResponseBase<VeiculoPedido>) => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    criar(codigoPedido: number, veiculo: CriacaoVeiculoRequest): Promise<ResponseBase<VeiculoPedido>> {
        return new Promise<ResponseBase<VeiculoPedido>>((resolve, reject) => {
            this.post(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculo`,
                veiculo
            ).subscribe(
                (result: ResponseBase<VeiculoPedido>) => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    editar(codigoPedido: number, veiculo: AtualizacaoVeiculoPedidoRequest): Promise<ResponseBase<VeiculoPedido>> {
        return new Promise<ResponseBase<VeiculoPedido>>((resolve, reject) => {
            this.put(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculo/${veiculo.placaAlterada}`,
                veiculo
            ).subscribe(
                (result: ResponseBase<VeiculoPedido>) => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                },
                () => {
                    this.isLoadingService.isLoading = of(false);
                }
            );
        });
    }

    remover(codigoPedido: number, codigoOperacao: string, rowVersion: string, placa: string) {
        return new Promise<any>((resolve, reject) => {
            this.delete(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/veiculo/${placa}?rowVersion=${rowVersion}&tipoOperacao=${codigoOperacao}`
            ).subscribe(
                result => {
                    resolve(result);
                },
                err => {
                    this.error(err), reject(err);
                }
            );
        });
    }
}
