import { ResponseBase, PaginatedResponse } from './../interfaces/response-base';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ModalService } from './modal.service';
import { IsLoadingService } from './is-loading.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BaseService<T> {
    public headers: HttpHeaders;
    public params: HttpParams;
    public date = new Date();
    public urlBase: string;
    public urlUpdateStatus: string;
    public urlGetAll: string;
    public urlDeleteAll: string;
    public urlUpdate: string;
    public urlInsert: string;

    constructor(
        protected httpClient: HttpClient,
        protected modalService: ModalService,
        protected isLoadingService: IsLoadingService
    ) {
        this.headers = new HttpHeaders()
            .append('Content-Type', 'application/json');
    }

    protected getBasePaginated(url: string): Promise<ResponseBase<PaginatedResponse<T>>> {
        return new Promise<ResponseBase<PaginatedResponse<T>>>((resolve, reject) => {
            this.httpClient.get(url)
                .subscribe(
                    (data: ResponseBase<PaginatedResponse<T>>) => {
                        resolve(data);
                    },
                    (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    }
                );
        });
    }

    protected getBase<Type>(url: string): Promise<Type & ResponseBase<T>> {
        return new Promise<Type & ResponseBase<T>>((resolve, reject) => {
            this.httpClient.get(url)
                .subscribe(
                    (data: Type & ResponseBase<T>) => {
                        resolve(data);
                    },
                    (error: HttpErrorResponse) => {
                        if (error.status === 200) {
                            resolve(error.error.text);
                        } else {
                            this.error(error);
                            reject(error);
                        }
                    }
                );
        });
    }

    protected postBase(url: string, object: T): Promise<ResponseBase<T>> {
        return new Promise<ResponseBase<T>>((resolve, reject) => {
            this.httpClient.post(url, object)
                .subscribe(
                    (data: ResponseBase<T>) => {
                        resolve(data);
                    },
                    (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    }
                );
        });
    }

    protected PutBase(url: string, object: T): Promise<ResponseBase<T>> {
        return new Promise<ResponseBase<T>>((resolve, reject) => {
            this.httpClient.put(url, object)
                .subscribe(
                    (data: ResponseBase<T>) => {
                        resolve(data);
                    },
                    (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    }
                );
        });
    }


    protected putBase(url: string, object: T): Promise<ResponseBase<T>> {
        return new Promise<ResponseBase<T>>((resolve, reject) => {
            this.httpClient.put(url, object)
                .subscribe(
                    (data: ResponseBase<T>) => {
                        resolve(data);
                    },
                    (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    }
                );
        });
    }

    protected patchBase(url: string, object: any): Promise<ResponseBase<T>> {
        return new Promise<ResponseBase<T>>((resolve, reject) => {
            this.httpClient.patch(url, object)
                .subscribe(
                    (result: ResponseBase<T>) => {
                        resolve(result);
                    },
                    (error: HttpErrorResponse) => {
                        this.error(error);
                        reject();
                    }
                );
        });
    }

    protected deleteBase(url: string, object: T): Promise<ResponseBase<T>> {
        return new Promise<ResponseBase<T>>((resolve, reject) => {
            this.httpClient.delete(url, object)
                .subscribe(
                    (data: ResponseBase<T>) => {
                        resolve(data);
                    },
                    (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    }
                );
        });
    }


    get<T>(url: string): Observable<T> {
        return this.httpClient
            .get<T>(url,
                {
                    params: this.params,
                    headers: this.headers
                });
    }

    delete<T>(url: string): Observable<T> {
        return this.httpClient
            .delete<T>(
                url,
                {
                    params: this.params,
                    headers: this.headers
                }
            );
    }

    patch(url: string, body: any = {}): Observable<T> {
        return this.httpClient
            .patch<T>(url, body);
    }

    post<T>(url: string, body: any = {}): Observable<T> {
        return this.httpClient
            .post<T>(
                url,
                body,
                {
                    params: this.params,
                    headers: this.headers
                });
    }

    put<T>(url: string, body: any): Observable<T> {
        return this.httpClient
            .put<T>(
                url,
                body,
                {
                    params: this.params,
                    headers: this.headers
                }
            );
    }

    public error(httpErrorResponse: HttpErrorResponse, reload: boolean = false) {
        if (reload) {
            const observer = this.modalService.event.subscribe(
                (event: { val: boolean, type: string }) => {
                    if (event.val === true) {
                        observer.unsubscribe();
                        window.location.reload();
                    }
                });
        }
        if (httpErrorResponse.status === 504) {
            this.modalService.error('O serviço de terceiros não retornou no tempo de operação esperado.');
        } else if (!!httpErrorResponse.error 
                   && (httpErrorResponse.status !== 500
                   && httpErrorResponse.status !== 401
                   && httpErrorResponse.status !== 400)) {
            if (!!httpErrorResponse.error.error && httpErrorResponse.error.error === 'invalid_token') {
                return;
            }
            if (!!httpErrorResponse.error.errors && !!httpErrorResponse.error.errors.length) {
                const errors = httpErrorResponse.error.errors;
                if (!!httpErrorResponse.error.message) {
                    errors.unshift({ message: httpErrorResponse.error.message });
                }
                this.showErrors(httpErrorResponse.error.errors);
            } else if (!!httpErrorResponse.error.message) {
                this.modalService.error(httpErrorResponse.error.message);
            } else if (!!httpErrorResponse.error.error_description) {
                let msg = '';
                switch (httpErrorResponse.error.error_description) {
                    case 'Bad credentials':
                        msg = 'Usuário ou senha inválidos.';
                        break;
                    case 'User credentials have expired':
                        msg = 'Sua senha expirou, cadastre uma nova.';
                        break;
                    default:
                        msg = httpErrorResponse.error.error_description;
                }
                this.modalService.important(msg);
            } else if (httpErrorResponse.error instanceof Blob || httpErrorResponse.error.error instanceof Blob) {
                const blobObserver = this.blobToString(!!httpErrorResponse.error ?
                    (!!httpErrorResponse.error.error ? httpErrorResponse.error.error.message : httpErrorResponse.error) :
                    httpErrorResponse.error).subscribe((res: any) => {
                        this.showErrors(res.errors.length > 0 ? res.errors : res);
                        blobObserver.unsubscribe();
                    });
            } else {
                this.modalService.error(httpErrorResponse.error.message ||
                    'Estamos com problemas técnicos no momento. Tente novamente mais tarde.');
            }
        } else if ((httpErrorResponse.status === 404 || httpErrorResponse.status === 400) && !!httpErrorResponse.error) {
            let errors = httpErrorResponse.error.message;

            if (!!httpErrorResponse.error.error_description) {
                if (httpErrorResponse.error.error_description === 'User credentials have expired') {
                    errors = 'Sua senha expirou, Cadastre uma nova senha diferente da atual.';
                }

                if (httpErrorResponse.error.error_description === 'Bad credentials') {
                    errors = 'Usuário/Senha inválidos.';
                }

                if (httpErrorResponse.error.error_description === 'User is disabled') {
                    errors = 'Entidade ou usuário inativo.';
                }
            }

            if (!!httpErrorResponse.error.errors && httpErrorResponse.error.errors.length && !!httpErrorResponse.error.errors[0].message) {
                errors = httpErrorResponse.error.errors[0].message;
            }

            console.log(errors);

            if (!!errors) {
                this.modalService.important(`<b>${errors}</b>`);
            }
        } else if (httpErrorResponse.status === 500 || httpErrorResponse.status !== 401) {
                console.log('error', httpErrorResponse);
                if (!!httpErrorResponse.error && !!httpErrorResponse.error.message) {
                    this.modalService.error(httpErrorResponse.error.message ||
                        'Estamos com problemas técnicos no momento. Tente novamente mais tarde.');
                    }
        }
        return;
    }

    protected showErrors(errors: any) {
        if (errors.length > 0) {
            this.modalService
                .error(
                    errors.map(
                        (item: any) => item.message.replace(/[\"\]\[]/g, '')
                    ).join('<br/><br>')
                );
        } else {
            this.modalService
                .error(
                    errors.message.replace(/[\"\]\[]/g, '').concat('<br><br>')
                );
        }
    }

    private blobToString(blob: Blob): Observable<object> {

        const fileReader = new FileReader;
        const observable = new Observable<object>(observer => {
            fileReader.onload = () => {
                const result = <string>fileReader.result;
                observer.next(JSON.parse(result));
                observer.complete();
            };

        });
        fileReader.readAsText(blob);
        return observable;
    }
}
