import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {

    constructor(private matSnackBar: MatSnackBar) { }
    toasts: any[] = [];
    private config: MatSnackBarConfig = {
        horizontalPosition: 'right',
        verticalPosition: 'top'
    };

    private getSnackConfig(notificationClass: string[], time?: number, ): MatSnackBarConfig {
        return {
            ...this.config,
            duration: !!time ? time : 5000,
            panelClass: notificationClass
        };
    }

    public notification(msg: string, time?: number): void {
        this.matSnackBar.open(msg, null, this.getSnackConfig(['notification', 'notification-toastr'], time));
    }

    public success(msg: string, time?: number): void {
        this.matSnackBar.open(msg, null, this.getSnackConfig(['notification', 'success-toastr'], time));
    }

    public alert(msg: string, time?: number): void {
        this.matSnackBar.open(msg, null, this.getSnackConfig(['notification', 'alert-toastr'], time));
    }

    public danger(msg: string, time?: number): void {
        this.matSnackBar.open(msg, null, this.getSnackConfig(['notification', 'danger-toastr'], time));
    }
}
