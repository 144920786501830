import {TransportadorDocumentType} from './../pages/emissao/model/transportador.document.enum';
import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {ModalService} from './modal.service';
import {IsLoadingService} from './is-loading.service';
import {HttpClient} from '@angular/common/http';
import {URL_HELP_API} from 'environments/environment';
import {parseErrorBlob} from 'app/shared/helpers/format/http-util';
import {catchError} from 'rxjs/operators';
import {ResponseBase} from "../interfaces/response-base";
import {Transportador} from "../models/transportador/transportador";
import {PendenciasTransportador} from "../models/transportador/pendencias-transportador";

@Injectable({
    providedIn: 'root'
})
export class TransportadorService extends BaseService<any> {

    constructor(
        protected httpClient: HttpClient,
        protected modalService: ModalService,
        protected isLoadingService: IsLoadingService
    ) {
        super(httpClient, modalService, isLoadingService);
    }

    detalhe(documento: string) {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.get(
                `${URL_HELP_API.TRANSPORTADORES.BASE}/${documento}`)
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    consultarTransportador(documento: string): Promise<ResponseBase<Transportador>> {
        return new Promise<ResponseBase<Transportador>>((resolve, reject) => {
            this.get(`${URL_HELP_API.TRANSPORTADORES.BASE}/${documento}`)
                .subscribe(
                    (data: ResponseBase<Transportador>) => {
                        resolve(data);
                    },
                    err => { this.error(err), reject(err); });
        });
    }

    downloadDocumento(documento: string, documentType: TransportadorDocumentType): Promise<Blob> {
        let url = `${URL_HELP_API.TRANSPORTADORES.BASE}/${documento}/download/`;

        switch (documentType) {
            case TransportadorDocumentType.Carteirinha:
                url = url + 'carteirinha';
                console.log(url);
                break;

            case TransportadorDocumentType.Certificado:
                url = url + 'certificado';
                break;

            case TransportadorDocumentType.Extrato:
                url = url + 'extrato';
                break;

            case TransportadorDocumentType.RevalidacaoOrdinaria:
                url = url + 'relatorio-revalidacao';
                break;
        }
        return new Promise<any>((resolve, reject) => {
            this.httpClient.get(url, { responseType: 'blob' })
                .pipe(
                    catchError(parseErrorBlob)
                )
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    consultarRevalidacao(documento: string): Promise<ResponseBase<PendenciasTransportador>> {
        return new Promise<ResponseBase<PendenciasTransportador>>((resolve, reject) => {
            this.get(`${URL_HELP_API.TRANSPORTADORES.BASE}/consultar-revalidacao/${documento}`)
                .subscribe(
                    (data: ResponseBase<PendenciasTransportador>) => {
                        resolve(data);
                    },
                    err => { this.error(err), reject(err); });
        });
    }
}
