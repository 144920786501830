import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Perfil } from "app/models/usuario/perfil";
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { ModalService } from "./modal.service";
import { IsLoadingService } from "./is-loading.service";
import { FilterGrid } from "app/models/filter-grid";
import { ResponseBase } from "app/interfaces/response-base";
import { URL_HELP_API } from "environments/environment";
import { TipoEntidade } from "app/models/usuario/tipo-entidade";

@Injectable({
    providedIn: 'root'
})
export class PerfilAcessoService extends BaseService<Perfil> {
    constructor(
        protected httpClient: HttpClient,
        protected modalService: ModalService,
        protected isLoadingService: IsLoadingService
    ) { super(httpClient, modalService, isLoadingService); }

    public listar(tipoEntidade: string): Promise<any> {
        const params = new HttpParams().set('searchExpression', `tipoEntidadeId:${tipoEntidade}`);

        return new Promise<any>((resolve, reject) => {
            this.httpClient.get(
                URL_HELP_API.PERFIS.URL,
                { params }
            ).subscribe(
                (result: any) => {
                    resolve(result.data.items);
                },
                (err) => { this.error(err), reject(err); }
            );
        });

    }

    public obterPerfis(filter: FilterGrid): Promise<ResponseBase<Perfil>> {
        let params = new HttpParams()
            .append('page', filter.pageNumber.toString())
            .append('size', filter.pageSize.toString())
            .append('sortBy', filter.sortBy)
            .append('sortDirection', filter.sortDirection);

        if (!!filter.searchValue && !!filter.searchType) {
            params = params.append('searchExpression', `${filter.searchType}:${filter.searchValue}`);
        }

        return new Promise<any>((resolve, reject) => {
            this.httpClient.get<ResponseBase<Perfil>>(URL_HELP_API.PERFIS.URL, { params })
                .subscribe((res: ResponseBase<Perfil>) => {
                    resolve(res);
                }, ((err: any) => {
                    this.error(err);
                    reject(err);
                })
                );
        });
    }

    public obterPerfil(id: number): Promise<ResponseBase<Perfil>> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.get<ResponseBase<Perfil>>(`${URL_HELP_API.PERFIS.URL}/${id}`)
                .subscribe((res: ResponseBase<Perfil>) => {
                    resolve(res);
                }, ((err: any) => {
                    this.error(err);
                    reject(err);
                })
                );
        });
    }

    public obterPerfilPadrao(): Promise<ResponseBase<Perfil>> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.get<ResponseBase<Perfil>>(`${URL_HELP_API.PERFIS.PERFIL_PADRAO}`)
                .subscribe((res: ResponseBase<Perfil>) => {
                    resolve(res);
                }, ((err: any) => {
                    this.error(err);
                    reject(err);
                })
                );
        });
    }

    public obterFuncionalidades() {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.get<ResponseBase<Perfil>>(URL_HELP_API.FUNCIONALIDADES.URL)
                .subscribe((res: ResponseBase<Perfil>) => {
                    resolve(res);
                }, ((err: any) => {
                    this.error(err);
                    reject(err);
                })
                );
        });
    }

    public obterTiposEntidade() {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.get<ResponseBase<TipoEntidade>>(URL_HELP_API.TIPOSENTIDADE.URL)
                .subscribe((res: ResponseBase<TipoEntidade>) => {
                    resolve(res);
                }, ((err: any) => {
                    this.error(err);
                    reject(err);
                })
                );
        });
    }

    public removeItem(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.delete(`${URL_HELP_API.PERFIS.URL}/${id}`).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (err: HttpErrorResponse) => {
                    this.error(err);
                    reject(false);
                }
            );
        });
    }

    public insertItem(perfil: Perfil): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.post(`${URL_HELP_API.PERFIS.URL}`, perfil).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (err: HttpErrorResponse) => {
                    this.error(err);
                    reject(false);
                }
            );
        });
    }

    public updateItem(perfil: Perfil): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpClient.put(`${URL_HELP_API.PERFIS.URL}/${perfil.id}`, perfil).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (err: HttpErrorResponse) => {
                    this.error(err);
                    reject(false);
                }
            );
        });
    }
}