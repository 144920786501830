// tslint:disable-next-line:max-line-length
import {ModalDetalheRegistroComponent} from './pages/relatorio/pedidos/cancelados/modal-detalhe-registro/modal-detalhe-registro.component';
// tslint:disable-next-line:max-line-length
import {ModalAnaliseConcluidaComponent} from './pages/relatorio/pedidos/pendentes/modal-analise-concluida/modal-analise-concluida.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuardService} from './services/auth-guard.service';
import {BrowserModule} from '@angular/platform-browser';

import {APP_BASE_HREF, CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {Approutes} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {SpinnerComponent} from './shared/spinner.component';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {ModalComponent} from './components/modal/modal.component';
import localePt from '@angular/common/locales/pt';
import localeExtraPt from '@angular/common/locales/extra/pt';
import {ModalDetalhePerfilComponent} from './pages/admin/perfil-acesso/detalhe/modal-detalhe-perfil.component';
import {CnpjViewComponent} from './pages/admin/cnpj/lista/cnpj-view/cnpj-view.component';

import {NgxPermissionsModule} from 'ngx-permissions';

registerLocaleData(localePt, 'pt', localeExtraPt);

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        AuthGuardService,
        DatePipe,
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    entryComponents: [
        ConfirmModalComponent,
        ModalComponent,
        ModalAnaliseConcluidaComponent,
        ModalDetalheRegistroComponent,
        CnpjViewComponent,
        ModalDetalhePerfilComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(Approutes),
        SharedModule,
        NgxPermissionsModule.forRoot()
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
