import { ContaBancaria } from '../entidade/entidade-conta-bancaria';
import { TipoEntidadeEnum } from './tipo-entidade-enum';

export class Entidade {
    id?: number;
    cnpj: string;
    cnpjDicotomico: string;
    razaoSocial: string;
    nomeFantasia: string;
    codigoEntidadeANTT: number;
    codigoEntidadeSuperior: string;
    email: string;
    telefone: string;
    ramal: string;
    cep: string;
    logradouro: string;
    numero: string;
    complemento: string;
    municipio: string;
    uf: string;
    tipo: TipoEntidadeEnum;
    status: string;
    pontoAtendimento: boolean;
    bairro: string;
    contasBancarias: ContaBancaria[];
    /**
     *
     */
    constructor(
        id: number = 0,
        cnpj: string = '',
        cnpjDicotomico: string = '',
        razaoSocial: string = '',
        nomeFantasia: string = '',
        codigoEntidadeANTT: number = 0,
        email: string = '',
        telefone: string = '',
        ramal: string = '',
        cep: string = '',
        logradouro: string = '',
        numero: string = '',
        complemento: string = '',
        municipio: string = '',
        uf: string = '',
        tipo: TipoEntidadeEnum = null,
        status: string = 'INATIVO',
        pontoAtendimento: boolean = false,
        codigoEntidadeSuperior?: string,
        contasBancarias: ContaBancaria[] = []
    ) {
        this.id = id;
        this.cnpj = cnpj;
        this.cnpjDicotomico = cnpjDicotomico;
        this.razaoSocial = razaoSocial;
        this.nomeFantasia = nomeFantasia;
        this.codigoEntidadeANTT = codigoEntidadeANTT;
        this.codigoEntidadeSuperior = codigoEntidadeSuperior;
        this.email = email;
        this.telefone = telefone;
        this.ramal = ramal;
        this.cep = cep;
        this.logradouro = logradouro;
        this.numero = numero;
        this.complemento = complemento;
        this.municipio = municipio;
        this.uf = uf;
        this.tipo = tipo;
        this.status = status;
        this.pontoAtendimento = pontoAtendimento;
        this.contasBancarias = contasBancarias;
    }
}
