import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsLoadingService {
  private enabled = true;
  public isCalling = new BehaviorSubject<boolean>(false);
  public isLoading: Observable<boolean>;
  public loadingStack = new Array<boolean>();

  constructor() {
    this.isCalling.subscribe((isLoading: boolean) => {
      if (this.enabled) {
        isLoading ? this.loadingStack.push(true) : this.loadingStack.pop();
        this.loadingStack.length > 0 ? this.show() : this.dismiss();
      }
    });
  }

  public show(): void {
    this.isLoading = of(true);
  }

  public dismiss(): void {
    this.isLoading = of(false);
  }

  public enable() {
    this.enabled = true;
  }

  public disable() {
    this.enabled = false;
  }

}
