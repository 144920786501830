import { NgxRole, NgxRolesService, NgxPermissionsService, NgxPermissionsConfigurationService } from "ngx-permissions";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionLevel, PermissionLevelMap } from "../models/authentication/permission-level";
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { AppInjector } from "app/app.injector.service";

@Component({ template: '' })

export class BaseComponent implements OnDestroy {
    protected role: NgxRole;

    public Permission = PermissionLevel;

    public PermissionMap = PermissionLevelMap;

    protected rolesService: NgxRolesService;

    protected permissionService: NgxPermissionsService;

    protected permissionConfigurationService: NgxPermissionsConfigurationService;

    constructor(
        protected router: Router 
    ) {
        const injector = AppInjector.getInjector();

        this.router = injector.get(Router);

        this.rolesService = injector.get(NgxRolesService);

        this.permissionService = injector.get(NgxPermissionsService);

        this.permissionConfigurationService = injector.get(NgxPermissionsConfigurationService);

        this.setRoles();
    }

    ngOnDestroy() {
        this.permissionService.flushPermissions();
    }

    protected setRoles(): void {
        this.role = this.rolesService.getRole(this.router.url);

        if (!!this.role && !!this.role.validationFunction) {
            (this.role.validationFunction as string[]).map(funcionalidade => {
                this.permissionService.addPermission(funcionalidade);
            });
        }
    }

    public getClaim(name: string): boolean {
        const permission = this.permissionService.getPermission(name);
        return !permission;
    }
}