import { Directive, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[formControlName]'
})
export class ChangeFormDirective {
    public change$ = fromEvent(this.element, 'change').pipe(shareReplay(1));
    public blur$ = fromEvent(this.element, 'blur').pipe(shareReplay(1));
    public focus$ = fromEvent(this.element, 'focus').pipe(shareReplay(1));

    constructor(private host: ElementRef<HTMLFormElement>) { }

    get element() {
        return this.host.nativeElement;
    }
}