import { TokenModel } from './../models/authentication/token.model';
import { AuthenticationModel } from './../models/authentication/authentication.model';
import { IsLoadingService } from './is-loading.service';
import { ModalService } from 'app/services/modal.service';
import { BaseService } from './base.service';
import { URL_HELP_API } from 'environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LoginModel } from './../models/authentication/login.model';
import { AuthenticationService } from 'app/services/authentication.service';
import { Injectable } from '@angular/core';
import { ValidateUsuarioModel } from 'app/models/authentication/validateusuario.model';
import { ResponseBase } from 'app/interfaces/response-base';
import { ValidateUsuarioResultModel } from 'app/models/authentication/validateusuarioresult.model';
import { CadastrarSenhaModel } from 'app/models/authentication/cadastrarsenha.model';
import { RecadastrarSenhaModel } from 'app/models/authentication/recadastrarsenha.model';
import { formatDate } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class LoginService extends BaseService<LoginService> {
    constructor(
        protected readonly httpClient: HttpClient,
        private readonly authService: AuthenticationService,
        protected readonly modalService: ModalService,
        protected readonly loadingService: IsLoadingService
    ) {
        super(httpClient, modalService, loadingService);
    }

    public validateUsuario(usuario: ValidateUsuarioModel) {
        return new Promise<ResponseBase<ValidateUsuarioResultModel>>((resolve, reject) => {
            this.httpClient.post(
                `${URL_HELP_API.USUARIOS.VALIDATE_USUARIOS}`,
                usuario
            ).subscribe(
                (response: ResponseBase<ValidateUsuarioResultModel>) => {
                    resolve(response);
                },
                (err) => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    public cadastrarSenha(dadosSenha: CadastrarSenhaModel) {
        const params = new HttpParams()
            .append('cpf', dadosSenha.cpf)
            .append('ip', dadosSenha.ip)
            .append('latitude', dadosSenha.latitude.toString())
            .append('longitude', dadosSenha.longitude.toString())
            .append('localizacao', dadosSenha.localizacao)
            .append('senha', dadosSenha.senha);

        const headers = this.authService.getAuthHeader('Bearer');
        return new Promise<TokenModel>((accept, reject) => {
            this.httpClient.post<any>(URL_HELP_API.USUARIOS.CADASTRO_SENHA, params,
                { headers: headers.append('Content-Type', 'application/x-www-form-urlencoded') })
                .subscribe(
                    (res: TokenModel) => {
                        accept(res);
                    }, (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    });
        });
    }

    public recadastrarSenha(dadosSenha: RecadastrarSenhaModel) {
        let params = new HttpParams()
            .append('cpf', dadosSenha.cpf)
            .append('senhaNova', dadosSenha.senhaNova);

        if (!!dadosSenha.dataNascimento) {
            params = params.append('dataNascimento', formatDate(dadosSenha.dataNascimento, 'yyyy-MM-dd', 'pt-BR'));
        }

        if (!!dadosSenha.email) {
            params = params.append('email', dadosSenha.email);
        }

        if (!!dadosSenha.nomeMae) {
            params = params.append('nomeMae', dadosSenha.nomeMae);
        }

        if (!!dadosSenha.senhaAntiga) {
            params = params.append('senhaAntiga', dadosSenha.senhaAntiga);
        }

        const headers = this.authService.getAuthHeader('Bearer');
        return new Promise<AuthenticationModel>((accept, reject) => {
            this.httpClient.post(URL_HELP_API.USUARIOS.RECADASTRO_SENHA, params,
                { headers: headers.append('Content-Type', 'application/x-www-form-urlencoded') })
                .subscribe(
                    (res: AuthenticationModel) => {
                        accept(res);
                    }, (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    });
        });
    }

    public login(login: LoginModel): Promise<boolean> {
        return new Promise<boolean>(async (accept, reject) => {
            await this.getAuthenticationToken(login).then((res: AuthenticationModel) => {
                this.authService.setAuthenticationToken(res);
                accept(true);
            }).catch((res: HttpErrorResponse) => {
                this.authService.setAuthenticationError({
                    error: res.error.error,
                    error_description: res.error.error_description
                });
                reject(false);
            });
        });
    }

    public logout() {
        this.authService.logout();
    }

    private async getAuthenticationToken(login: LoginModel): Promise<AuthenticationModel> {
        const formData = new FormData();
        formData.append('username', login.username);
        formData.append('password', login.password);
        formData.append('grant_type', 'password');

        return new Promise<AuthenticationModel>((accept, reject) => {
            this.httpClient.post<any>(URL_HELP_API.AUTENTICAR.GET_TOKEN_V2, formData, { headers: this.authService.getAuthHeader('Basic') })
                .subscribe(
                    (res: AuthenticationModel) => {
                        accept(res);
                    }, (error: HttpErrorResponse) => {
                        this.error(error);
                        reject(error);
                    });
        });
    }
}
