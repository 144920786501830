import { AuthenticationModel } from './../models/authentication/authentication.model';
import { URL_HELP_API } from 'environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationErrorModel } from 'app/interfaces/authenticationerror-model';
import { Router } from '@angular/router';
import { NgxRolesService } from 'ngx-permissions';
import { PermissionLevel, PermissionLevelMap } from 'app/models/authentication/permission-level';
import { ModalService } from './modal.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private isPermissionsSet = false;
    private readonly authKey = 'fog53sft';
    private readonly authErrorKey = 'eQ5oUk';
    private readonly usernameKey = 'GmFBA2gg9w';
    private readonly levelKey = '2mmdOU';
    private helper = new JwtHelperService();
    public previousUrl = new Array<string>();
    private readonly url = URL_HELP_API.AUTENTICAR.GET_TOKEN_V2;
    private readonly credentials = { user: 'front-angular', password: '1234' };
    constructor(
        private readonly httpClient: HttpClient,
        private readonly router: Router,
        private readonly rolesService: NgxRolesService,
        private readonly modalService: ModalService
    ) { }
    public get currentToken(): AuthenticationModel {
        const token = JSON.parse(window.sessionStorage.getItem(this.authKey));
        if (!!token) {
            return token;
        } else {
            return null;
        }
    }

    public get userObject(): AuthenticationModel {
        const authenticationModel =
            this.helper.decodeToken(JSON.parse(window.sessionStorage.getItem(this.authKey)).access_token) as AuthenticationModel;

        if (!this.isPermissionsSet) {
            authenticationModel.perfil.funcionalidades.forEach(
                funcionalidade => {
                    this.rolesService.addRoles(
                        {
                            [`/portal${funcionalidade.rota}`]: funcionalidade.acoes.map((acao: any) => PermissionLevelMap.get(acao.id))
                        }
                    );
                }
            );

            this.isPermissionsSet = true;
        }
        return authenticationModel;
    }

    public get currentError(): AuthenticationErrorModel {
        const error = JSON.parse(window.sessionStorage.getItem(this.authErrorKey));
        if (!!error) {
            return error;
        } else {
            return null;
        }
    }

    public get username(): string {
        const login = window.sessionStorage.getItem(this.usernameKey);
        if (!!login) {
            return login;
        } else {
            return null;
        }
    }

    public get acessaAntt(): boolean {
        const acessaAntt = JSON.parse(window.sessionStorage.getItem(this.levelKey));
        if (!!acessaAntt) {
            return acessaAntt;
        } else {
            return null;
        }
    }

    public clear(): void {
        window.sessionStorage.removeItem(this.authKey);
        window.sessionStorage.removeItem(this.authErrorKey);
        window.sessionStorage.removeItem(this.usernameKey);
        window.sessionStorage.removeItem(this.levelKey);
        this.rolesService.flushRoles();
    }

    public setAuthenticationToken(authentication: AuthenticationModel) {
        sessionStorage.setItem(this.authKey, JSON.stringify({
            access_token: authentication.access_token,
            refresh_token: authentication.refresh_token
        }));
    }

    public setAuthenticationError(res: AuthenticationErrorModel) {
        sessionStorage.setItem(this.authErrorKey, JSON.stringify(res));
    }

    public setAuthenticationUsername(username: string) {
        sessionStorage.setItem(this.usernameKey, username);
    }

    public setAuthenticationLevel(acessaAntt: boolean) {
        sessionStorage.setItem(this.levelKey, JSON.stringify(acessaAntt));
    }

    public isAuthenticated(): boolean {
        if (!this.currentToken) {
            this.clear();
            return false;
        }
        return true;
    }

    public getRouteAccess(route: string): boolean {
        const userSubRoutes = this.userObject.perfil.funcionalidades.map(i => `/portal${i.rota}`);
        const authenticatedRoutes = userSubRoutes.map(i => {
            const subRoutes = i.split('/');
            return subRoutes.splice(0, subRoutes.length - 1).join('/');
        });

        return !!authenticatedRoutes.find(i => route.indexOf(i) > -1);

    }

    public async refreshToken(): Promise<AuthenticationModel> {
        const formData = new FormData();
        formData.append('refresh_token', this.currentToken.refresh_token);
        formData.append('grant_type', 'refresh_token');

        return new Promise<AuthenticationModel>((accept, reject) => {
            this.httpClient.post(this.url, formData)
                .subscribe(
                    ((res: AuthenticationModel) => {
                        this.setAuthenticationToken(res);
                        accept(res);
                    }),
                    ((err) => {
                        reject(err);
                    })
                );
        });
    }

    public async revogateToken(): Promise<boolean> {
        return new Promise<boolean>((accept, reject) => {
            this.httpClient.get(`${URL_HELP_API.USUARIOSCONECTADOS.REVOKE}`)
                .subscribe(() => {
                    accept(true);
                }, (() => {
                    reject(false);
                }));
        });
    }

    public logout(revogateToken = true) {
        if (revogateToken) {
            this.revogateToken().then(() => {
                this.clear();
                this.modalService.success('Você foi desconectado com sucesso');
                setTimeout(() => {
                    this.router.navigateByUrl('login');
                }, 200);
            });
        } else {
            this.clear();
            this.router.navigateByUrl('/login');
        }
    }

    public getAuthHeader = (type: 'Bearer' | 'Basic'): HttpHeaders => {
        const headers = new HttpHeaders();
        return headers.append('Authorization', `${type} ${window.btoa(`${this.credentials.user}:${this.credentials.password}`)} `);
    }

    public hasPermission(userProfile: AuthenticationModel, feature: string, grupo: string, action: PermissionLevel) {
        if(!userProfile || !userProfile.perfil){
            return false;
        }

        let funcionalidades = userProfile.perfil.funcionalidades;

        return !!funcionalidades && funcionalidades.some(x => x.nome === feature && x.grupo === grupo && x.acoes.some(y => y['nome'] === action));
    }
}
