/*  */import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[cpfMask]'
})
export class CpfMaskDirective {

    @Output()
    cpfValueChange = new EventEmitter<string>();

    @HostListener('input', ['$event'])
    inputEvent(event: KeyboardEvent) {
        this._eventHandler(event);
    }

    private _eventHandler(event) {
        const input = (event.target as HTMLInputElement);
        let value = input.value;

        value = value.replace(/[^0-9]+/g, '');
        if (value.length > 3) { value = value.substr(0, 3) + '.' + value.substr(3); }
        if (value.length > 7) { value = value.substr(0, 7) + '.' + value.substr(7); }
        if (value.length > 11) { value = value.substr(0, 11) + '-' + value.substr(11, 2); }

        input.value = value;
        this.cpfValueChange.emit(value);
    }

}
