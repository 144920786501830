export enum PermissionLevel {
    Consultar = 'Consultar',
    Cadastrar = 'Cadastrar',
    Editar = 'Editar',
    Remover = 'Remover',
    Ativar = 'Ativar',
    Inativar = 'Inativar',
    Desconectar = 'Desconectar',
    Detalhes = 'Detalhes',
    Download = 'Download'
}

export const PermissionLevelMap = new Map<number, string>(
    [
        [1, PermissionLevel.Consultar],
        [2, PermissionLevel.Cadastrar],
        [3, PermissionLevel.Editar],
        [4, PermissionLevel.Remover],
        [5, PermissionLevel.Ativar],
        [6, PermissionLevel.Inativar],
        [7, PermissionLevel.Desconectar],
        [8, PermissionLevel.Detalhes],
        [9, PermissionLevel.Download]
    ]
);
