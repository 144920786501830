import {
    Component,
    Input,
    OnDestroy,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router';
import { of } from 'rxjs';
import { IsLoadingService } from 'app/services/is-loading.service';

@Component({
    selector: 'app-spinner',
    template: `<div class="preloader" *ngIf="isSpinnerVisible | async">
        <div class="spinner"> 
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
    </div>`,
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {
    @Input() public backgroundColor = 'rgba(0, 115, 170, 0.60)';

    constructor(
        private router: Router,
        private isLoadingService: IsLoadingService
    ) { }

    get isSpinnerVisible() {
        return this.isLoadingService.isLoading;
    }

    ngOnInit() {
        this.router.events.subscribe(
            event => {
                if (event instanceof NavigationStart) {
                    this.isLoadingService.show();
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError
                ) {
                    this.isLoadingService.dismiss();
                }
            },
            () => {
                this.isLoadingService.dismiss();
            }
        );
    }
}
