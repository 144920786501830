import { CnpjPipe } from './cnpj.pipe';
import { CpfPipe } from './cpf.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cpfCnpj' })
export class CpfCnpjPipe implements PipeTransform {
    private cpfPipe = new CpfPipe();
    private cnpjPipe = new CnpjPipe();

    transform(cpfCnpj: string): string {
        let value = cpfCnpj;
        if (!value) { return ''; }

        const cpfCnpjValor = value.replace(/[a-zA-Z]/g, '');

        if (cpfCnpjValor.length === 11) {
            value = this.cpfPipe.transform(cpfCnpjValor);
        } else if (cpfCnpjValor.length === 14) {
            value = this.cnpjPipe.transform(cpfCnpjValor);
        }

        return value.replace(/^[a-zA-Z]/g, '');
    }
}
