import { Injectable } from '@angular/core';
import { PaginatedFilter } from 'app/models/pagination/paginated-filter';
import { BaseService } from './base.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ModalService } from './modal.service';
import { IsLoadingService } from './is-loading.service';
import { URL_HELP_API } from 'environments/environment';
import { Entidade } from 'app/models/usuario/entidade';
import { TipoEntidadeMap } from 'app/models/usuario/tipo-entidade-enum';
import { CnpjEntidade } from 'app/pages/admin/cnpj/models/cnpj-entidade';
import {ContaBancaria} from '../models/entidade/entidade-conta-bancaria';
import {catchError} from 'rxjs/operators';
import {parseErrorBlob} from '../shared/helpers/format/http-util';

@Injectable({
    providedIn: 'root'
})
export class EntidadeService extends BaseService<Entidade | CnpjEntidade> {
    constructor(
        public httpClient: HttpClient,
        public modalService: ModalService,
        public isLoadingService: IsLoadingService) {
        super(httpClient, modalService, isLoadingService);
    }

    getPaginatedEntidade(filter: PaginatedFilter) {
        return this.getBasePaginated(`${URL_HELP_API.ENTIDADES.URL}${filter.filterKeys()}`);
    }

    insertEntidade(entidade: CnpjEntidade, contas: ContaBancaria[]) {
        entidade.contasBancarias = contas;
        return this.postBase(`${URL_HELP_API.ENTIDADES.URL}`, entidade);
    }

    updateEntidade(entidade: CnpjEntidade, id: string, contas: ContaBancaria[]) {
        entidade.contasBancarias = contas;
        return this.putBase(`${URL_HELP_API.ENTIDADES.URL}/${id}`, entidade);
    }

    changeStatusEntidade(entidade: Entidade, flagAtivoInativo: string) {
        return this.patchBase(`${URL_HELP_API.ENTIDADES.ATIVAR_INATIVAR}/${entidade.id}`, { flagAtivoInativo: flagAtivoInativo });
    }

    listar(entidades, tipo): Promise<Array<Entidade>> {
        return new Promise<any>((resolve, reject) => {
            if (tipo === 'HIERARQUIA') {
                const entidadesFiltradas = entidades.slice();
                resolve(entidadesFiltradas);
            } else {
                const entidadesFiltradas = entidades.filter(item => item.tipo.toLowerCase() === tipo.toLowerCase());
                resolve(entidadesFiltradas);
            }
        });
    }

    popularArvore() {
        return new Promise<any>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.ENTIDADES.URL}/arvore-entidade`
            ).subscribe(
                (response) => {
                    resolve(response);
                },
                (err) => {
                    this.error(err), reject(err);
                }
            );
        });
    }

    processarArvore(arvore: Array<Entidade>) {
        return {
            entidades: arvore,
            tipos: [...new Set(arvore.map(item => TipoEntidadeMap.get(item.tipo.toUpperCase())))]
        };
    }

    downloadPlanilhaTaxaEntidades(idEntidades: string) {
        const URL = URL_HELP_API.ENTIDADES.PLANILHA_TAXA;
        const params = new HttpParams().set('entidades', idEntidades);
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel');
        return this.httpClient.get(URL, {params, headers, responseType: 'blob'}).pipe(catchError(parseErrorBlob));
    }

}
