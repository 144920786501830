import { Component, OnInit, Input } from '@angular/core';
import { Entidade } from 'app/models/usuario/entidade';
import { ModalService } from 'app/services/modal.service';
import { EntidadeService } from 'app/services/entidade.service';
import { PaginatedFilter } from 'app/models/pagination/paginated-filter';
import { PaginatedResponse, ResponseBase } from 'app/interfaces/response-base';
import { TipoEntidadeMap, TipoEntidadeEnum } from 'app/models/usuario/tipo-entidade-enum';

@Component({
    selector: 'app-cnpj-view',
    templateUrl: './cnpj-view.component.html',
    styleUrls: ['./cnpj-view.component.scss']
})
export class CnpjViewComponent implements OnInit {
    @Input() public  readonly data: Entidade;
    public entidadeSuperior: Entidade;
    public tipoEntidade = TipoEntidadeEnum;
    public tipoEntidadeMap = TipoEntidadeMap;
    public ready = false;
    constructor(
        private readonly modalService: ModalService,
        private readonly entidadeService: EntidadeService
    ) { }

    public ngOnInit(): void {
        setTimeout(() => {
            if (TipoEntidadeEnum[this.data.tipo.toUpperCase()] !== TipoEntidadeEnum.ADMINISTRADORA) {
                this.entidadeService
                    .getPaginatedEntidade(new PaginatedFilter({
                        searchExpression: `id:${this.data.codigoEntidadeSuperior}`
                    })).then(
                        (res: ResponseBase<PaginatedResponse<Entidade>>) => {
                            this.entidadeSuperior = res.data.items[0];
                        });
            }
            this.ready = true;
        }, 50);
    }

    public close() {
        this.modalService.hideComponent();
        this.ready = false;
    }

    public statusEntidade() {
        return {
            color: this.data.status === 'ATIVO' ? 'green' : 'red'
        };
    }


}
 
