export class AnalisePedidoModel {
    protocoloPedido: number;
    dataAberturaPedido: Date;
    ultimaAtualizacao: Date;
    tipoPedido: string;
    subPedido: string;
    cpfTac: string;
    nomeTac: string;
    sindicatoAbertura: string;
    usuario: string;
    nomeUsuario: string;
    statusPedido: string;
    descricaoStatus: string;
    veiculos: AnalisePedidoVeiculoModel[];
    valorPedido: number;
    tipoPagamento: string;
    valorPago: number;
    situacaoPagamento: string;
    id: string;
    serial: string;
    codigoAutenticacao: string;
    descricaoRegistro1: string;
    descricaoRegistro2: string;
}

export class AnalisePedidoVeiculoModel {
    veiculo: string;
    placa: string;
    codigoOperacao: string;
}
