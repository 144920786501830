import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from '../services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { tap, filter, switchMap, take } from 'rxjs/operators';
import { ModalService } from '../services/modal.service';
import { IsLoadingService } from 'app/services/is-loading.service';

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private failedHttpRequest: HttpRequest<any>;
    constructor(
        private readonly authService: AuthenticationService,
        private readonly modalService: ModalService,
        private readonly loadingService: IsLoadingService
    ) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let cloneRequest = request;
        this.loadingService.isCalling.next(true);

        if (!!request.body && request.body.get instanceof Function &&
            (request.body.get('grant_type') === 'password' || request.body.get('grant_type') === 'refresh_token')) {
            cloneRequest = request.clone({
                setHeaders: {
                    Authorization: this.authService.getAuthHeader('Basic').get('Authorization')
                }
            });
        } else {
            if (!!this.authService.currentToken) {
                cloneRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.authService.currentToken.access_token}`
                    }
                });
            }
        }

        return next.handle(cloneRequest).pipe(
            tap(
                () => { },
                error => {
                    this.loadingService.isCalling.next(false);
                    if (
                        (!!request.body &&
                            !!request.body.get &&
                            request.body.get instanceof Function &&
                            request.body.get('grant_type') === 'refresh_token') ||
                        (error.error === 'invalid_token' && error.status === 500)
                    ) {
                        this.authService.logout();
                    } else if (error.status === 401) {
                        if (this.refreshTokenInProgress) {
                            return this.refreshTokenSubject.pipe(
                                filter(result => result !== null),
                                take(1),
                                switchMap(() =>
                                    next.handle(this.addAuthenticationToken(request))
                                )
                            );
                        } else {
                            this.refreshTokenInProgress = true;
                            this.refreshTokenSubject.next(null);
                            this.failedHttpRequest = request;
                            return this.authService
                                .refreshToken()
                                .then((token: any) => {
                                    this.refreshTokenInProgress = false;
                                    this.refreshTokenSubject.next(token);
                                    this.addAuthenticationToken(this.failedHttpRequest);
                                    return next.handle(this.failedHttpRequest);
                                })
                                .catch((err: any) => {
                                    this.loadingService.isCalling.next(false);
                                    this.refreshTokenInProgress = false;
                                    if (!!err.message && (<string>err.message)
                                        .indexOf(`Cannot read property 'refresh_token' of null`) === -1) {
                                        this.modalService.important('Sessão expirada - Por favor efetue o login novamente');
                                    }
                                    this.authService.logout(false);
                                });
                        }
                    }
                },
                () => {
                    this.loadingService.isCalling.next(false);
                }
            )
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>) {
        const accessToken = this.authService.currentToken;
        if (!accessToken) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authService.currentToken.refresh_token}`
            }
        });
    }
}
