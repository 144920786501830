import { Url } from './url';
import { FuncionalidadeModel } from './../../models/authentication/funcionalidade.model';
import { RouteInfo } from './sidebar.metadata';
import { AuthenticationService } from '../../services/authentication.service';

export class RoutesDefinition {
    public set = false;
    public routes: RouteInfo[];

    constructor(private readonly authService: AuthenticationService) {
        // FORCING ROUTE ATRIBUITION
        this.routes = [];
        this.routes.push(...this.setRoutes(this.getUserRoutes(new Url().getRoutes())));
    }

    private getUserRoutes(routes: RouteInfo[]): RouteInfo[] {
        routes.forEach(level1 => {
            if (this.hasSubmenu(level1)) {
                level1.submenu.forEach(level2 => {
                    if (this.hasSubmenu(level2)) {
                        level2.submenu.forEach(level3 => {
                            if (!!(this.authService
                                .userObject
                                .perfil
                                .funcionalidades
                                .find(funcionalidade => level3.path.indexOf(funcionalidade.rota) > -1))) {
                                    level1.show = level2.show = level3.show = true;
                                }
                        });
                    } else {
                        if (!!(this.authService
                            .userObject
                            .perfil
                            .funcionalidades
                            .find(funcionalidade => level2.path.indexOf(funcionalidade.rota) > -1))) {
                                level1.show = level2.show = true;
                            }
                    }
                });
            } else {
                if (!!(this.authService
                    .userObject
                    .perfil
                    .funcionalidades
                    .find(funcionalidade => level1.path.indexOf(funcionalidade.rota) > -1))) {
                        level1.show = true;
                    }
            }
        });
        // this.authService.userObject.perfil.funcionalidades.map((funcionalidade: FuncionalidadeModel) => {
        //     const permissionLevel = funcionalidade.rota.replace('/', '').split('/');

        //     // LEVEL 1
        //     routes.map(item => {
        //         const index = item.path.indexOf(permissionLevel[0]);
        //         if (index > -1) { item.show = true; }

        //         // LEVEL 2  
        //         if (!!item.show && this.hasSubmenu(item)) {
        //             item.submenu.map(subItem => {
        //                 const indexSub = subItem.path.indexOf(permissionLevel[1]);
        //                 if (indexSub > -1) { subItem.show = true; }

        //                 // LEVEL 3
        //                 if (!!subItem.show && this.hasSubmenu(subItem)) {
        //                     subItem.submenu.map(subSubItem => {
        //                         if (subSubItem.path === funcionalidade.rota) {
        //                             subSubItem.show = true;
        //                         }
        //                     });
        //                 }
        //             });
        //         }
        //     });
        // });

        return routes;
    }

    private setRoutes(routes: RouteInfo[]): RouteInfo[] {
        const tempRoutes: RouteInfo[] = [];
        routes.map(item => {
            if (this.hasSubmenu(item)) {
                const subMenus: RouteInfo[] = this.setRoutes(item.submenu);
                tempRoutes.push(...subMenus);
            } else {
                item.path = `/portal${item.path}`;
            }
        });
        return routes;
    }

    private hasSubmenu(route: RouteInfo): boolean {
        return !!route.submenu && route.submenu.length > 0 ? true : false;
    }
}
