import { Animations } from './shared/animations/custom-animation';
import { Router, } from '@angular/router';
import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [Animations.routerTransition]
})

export class AppComponent {
    constructor(
        private readonly router: Router
    ) { }

    getState() {
        return this.router.routerState.snapshot.url;
    }

    public onResize(event: any): void { }
}
