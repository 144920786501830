import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

export const parseErrorBlob = (err: HttpErrorResponse): Observable<any> => {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
        reader.onloadend = (e) => {
            observer.error(JSON.parse(reader.result as string));
            observer.complete();
        }
    });
    reader.readAsText(err.error);
    return obs;
}