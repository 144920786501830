import { Directive, Input } from "@angular/core";
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from "@angular/forms";
import { Cnpj } from "../notations/cnpj.notation";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[valCnpj]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: CnpjValidatorDirective,
            multi: true
        }
    ]
})

export class CnpjValidatorDirective implements Validator {
    private validator: ValidatorFn;
    private _onChange: () => void;

    @Input()
    get validation(): ValidatorFn | null { return this.validator; }

    set validation(value: ValidatorFn | null) {
        this.validator = value;
        if (this._onChange) { this._onChange(); }
    }

    constructor() {
        this.validator = this.cnpjValidator();
    }

    validate(c: AbstractControl) {
        return this.validator(c);
    }

    private cnpjValidator(): ValidatorFn {
        return (c: AbstractControl) => {
            const isValid = new Cnpj(c.value).validate();
            if (isValid) {
                return null;
            } else {
                return {
                    cnpjvalidator: {
                        valid: false
                    }
                };
            }
        };
    }
    registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
}