import { ColumnType } from 'app/shared/data-table/models/column-type.model';
import { DatatableConfig } from './models/datatable-config.model';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DateFormat } from '../static/dateFormat-static';
import { PermissionLevel } from 'app/models/authentication/permission-level';
import { BaseComponent } from 'app/pages/base.component';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnChanges {
    @Input() paginated = false;
    @Input() dataSource: Array<any>;
    @Input() datatableConfig: DatatableConfig;
    @Output() paginateEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() slideEvent: EventEmitter<any> = new EventEmitter<any>();

    public columnType = ColumnType;
    public displayedItens: any[] = [];
    public displayedColumns: any[];
    public columns: string[];
    public actions: any[];
    public sliders: any[];
    public limit = 20;
    public pages = 0;
    public items = 0;
    public todos = false;
    public display = false;

    constructor(
        protected router: Router,
        private permissionService: NgxPermissionsService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        this.createDatatable();
    }

    getDate(value: any, format: 'date' | 'fulldate') {
        return format === 'date' ?
            DateFormat.getCustomMomentFormat(value, 'DD/MM/YYYY') :
            DateFormat.getCustomMomentFormat(value, 'DD/MM/YYYY HH:mm:ss');
    }

    private createDatatable(): void {
        if (!!this.dataSource && this.dataSource.length > 0) {

            this.displayedColumns = this.datatableConfig.columns
                .filter(config =>
                    config.type !== ColumnType.Hidden &&
                    config.type !== ColumnType.Action &&
                    (!!config.permission ? this.permissionService.getPermission(config.permission) : true)
                ).map(item => item);


            this.actions = this.datatableConfig.columns
                .filter(column =>
                    column.type === ColumnType.Action &&
                    (!!column.permission ? this.permissionService.getPermission(column.permission) : true)
                ).map(item => item);

            if (this.actions.length > 0) {
                this.displayedColumns.push({ key: 'Action', displayName: '&nbsp;', type: ColumnType.Action });
            }

            this.items = this.dataSource.length;

            if (this.paginated === true) {
                this.fakePaginate(
                    {
                        length: this.dataSource.length,
                        pageIndex: 0,
                        pageSize: this.limit,
                        previousPageIndex: 1
                    }
                );
            } else {
                this.displayedItens = this.dataSource;
            }
        }
    }


    fakePaginate(event: any) {
        this.displayedItens = this.dataSource.slice(event.pageIndex * event.pageSize, event.pageSize + event.pageIndex * event.pageSize);
        this.paginateEvent.emit(event);
    }

    slideToggleEvent(event: any, row: any) {
        this.slideEvent.emit({ event: event, row: row });
    }

    isChecked(row: any, column: any) {
        const myProp = row[column.parentKey];
        return column.value.split(':').findIndex((i: string) => i === myProp) === 0 ? true : false;
    }

    getDisplayName(column: any): string {
        if (column.type === ColumnType.Action) {
            return !!this.datatableConfig.actionDisplayName ? this.datatableConfig.actionDisplayName : '&nbsp';
        } else {
            return column.displayName;
        }
    }

    getActionColumnnName(column: any): string {
        if (column.type === ColumnType.Action) {
            return column.displayName;
        }
    }

    getColumnType(key: any): ColumnType {
        return key === 'Action' ? ColumnType.Action : this.datatableConfig.columns.find(i => i.key === key).type;
    }

    getActionCallback(column: any, row: any): any {
        return column.action(row);
    }

    getIcon(column: any): string {
        return `mdi ${column.icon} mat-mini-fab mat-button-base`;
    }

    getIconStyle(column: any) {
        if (!!column.color) {
            return {
                color: column.color.text,
                background: column.color.background,
                margin: '3%'
            };
        }
        return {
            color: '#000',
            background: '#F1C948',
            margin: '3%'
        };
    }

    getCustomStyle(row: any, column: any) {
        if(!!column.hasCustomColor){
            if (!!row['customColor']) {
                return {
                    color: row['customColor']
                };
            }
        }

        return {};
    }

    getEnumName(row: any, column: any) {
        const key = row[column.key];
        const enumValue = column.enumMap.get(key);
        if (!enumValue) {
            const enumReturn = column.enumMap.get(key.toUpperCase());
            return enumReturn && enumReturn.descricao;
        } else {
            return enumValue && enumValue.descricao;
        }
    }

    getSize(column: any): any {
        const style: any = { width: 'inherit', justifyContent: 'inherit', overflow: 'hidden' };

        if (!!column.size) {
            style.width = column.size;
        }
        if (column.type === ColumnType.Action) {
            style.textAlign = 'center';
        }
        return style;
    }

    getBodyStyle(column: any): any {
        if (column.type === ColumnType.Select || column.type === ColumnType.Slider || column.type === ColumnType.Action) {
            return { justifyContent: 'center' };
        }
        return;
    }

    selecionarTodos(event: any) {
        this.displayedItens.forEach(element => {
            element.selecionar = event.checked;
        });
    }

    selecionar(event: any) {
        if (!event.checked) {
            this.todos = false;
        }
    }
}
