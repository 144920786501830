import { Component, OnInit } from '@angular/core';
import { Perfil } from 'app/models/usuario/perfil';
import { ModalService } from 'app/services/modal.service';
import { IsLoadingService } from 'app/services/is-loading.service';
import { FormGroup, FormControl } from '@angular/forms';
import { PerfilAcessoService } from 'app/services/perfil-acesso.service';
import { Funcionalidade } from 'app/models/usuario/funcionalidade';
import { TipoEntidade } from 'app/models/usuario/tipo-entidade';
import { forkJoin } from 'rxjs';
import { ResponseBase } from 'app/interfaces/response-base';

@Component({
  selector: 'app-modal-detalhe-perfil',
  templateUrl: './modal-detalhe-perfil.component.html',
  styleUrls: ['./modal-detalhe-perfil.component.css']
})
export class ModalDetalhePerfilComponent implements OnInit {
  public id: number;
  public listTipos: Array<TipoEntidade>;
  public listGrupoFuncionalidade: Array<{nome: string, indeterminate: boolean, checked: boolean, funcionalidades: Funcionalidade[]}>;
  public data: Perfil = new Perfil();
  public newForm: FormGroup;

  constructor(
    private modalService: ModalService,
    private perfilAcessoService: PerfilAcessoService,
    private loadingService: IsLoadingService
  ) { }

  close() {
    this.modalService.hideComponent();
  }

  ngOnInit() {
    this.newForm = new FormGroup({
      nome: new FormControl(),
      perfilPadrao: new FormControl(false),
      tiposEntidade: new FormGroup({ }),
      acessaAntt: new FormControl()
    });
    
    this.listTipos = [];
    this.loadingService.show();
    forkJoin([
      this.perfilAcessoService.obterTiposEntidade(),
      this.perfilAcessoService.obterFuncionalidades(),
      this.perfilAcessoService.obterPerfil(this.id)
    ]).subscribe(result => {
      this.loadingService.dismiss();

      this.getListTipoEntidade(result[0]);
      this.getListFuncionalidade(result[1]);
      this.data = result[2].data as Perfil;
      this.newForm.patchValue({
        nome: this.data.nome,
        perfilPadrao: this.data.perfilPadrao,
        acessaAntt: this.data.acessaAntt ? 'S' : 'N'
      }, { emitEvent: false, onlySelf: true });
  
      const tiposEntidadeGroup = this.newForm.get('tiposEntidade') as FormGroup;
      this.data.tipos.forEach(tipo => {
        tiposEntidadeGroup.get(tipo.tipo).setValue(true);
      });

      this.data.funcionalidades.forEach(func => {
        const funcGroup = this.newForm.get(func.nome) as FormGroup;
        func.acoes.forEach(acao => funcGroup.get(acao.nome).setValue(true));
      });

      this.newForm.disable();
    }, () => this.loadingService.dismiss());
  }

  getListTipoEntidade(res: ResponseBase<TipoEntidade>) {
    this.listTipos = [];
    
    res.data.items.forEach(item => {
      const tiposEntidadeGroup = this.newForm.get('tiposEntidade') as FormGroup;
      tiposEntidadeGroup.addControl(item.tipo, new FormControl(false));
    });
    this.listTipos = res.data.items;
  }

  getListFuncionalidade(res: ResponseBase<Funcionalidade>) {
    this.listGrupoFuncionalidade = [];    
    
    const grupos = res.data.items.reduce(function (obj, item) {
      obj[item.grupo] = obj[item.grupo] || [];
      obj[item.grupo].push(item);
      return obj;
    }, Object.create(null));
    if (!!grupos) {
      this.listGrupoFuncionalidade = Object.keys(grupos).map(function(key) {
        return {nome: key, indeterminate: false, checked: false, funcionalidades: grupos[key]};
      });

      this.listGrupoFuncionalidade.forEach(grupo => {
        grupo.funcionalidades.forEach(func => {
          this.newForm.addControl(func.nome, new FormGroup({}));
          func.acoes.forEach(acao => {
            const funcGroup = this.newForm.get(func.nome) as FormGroup;
            funcGroup.addControl(acao.nome, new FormControl(false));
          });
        });
      });
    }
  }
}
