import { AuthGuardService } from './services/auth-guard.service';
import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
    {
        path: '',
        redirectTo: 'portal/home',
        pathMatch: 'full'
    },
    {
        path: 'portal',
        component: FullComponent,
        canActivateChild: [AuthGuardService],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'home',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'pedido',
                loadChildren: () => import('./pages/pedido/pedido.module').then(m => m.PedidoModule)
            },
            {
                path: 'cadastro/transportador',
                loadChildren: () => import('./pages/transportador/transportador.module').then(m => m.TransportadorModule)
            },
            {
                path: 'cadastro/veiculo',
                loadChildren: () => import('./pages/veiculo/veiculo.module').then(m => m.VeiculoModule)
            },
            {
                path: 'documentacao',
                loadChildren: () => import('./pages/emissao/emissao.module').then(m => m.EmissaoModule)
            },
            {
                path: 'relatorio',
                loadChildren: () => import('./pages/relatorio/relatorio.module').then(m => m.RelatorioModule)
            },
            {
                path: 'repasse',
                loadChildren: () => import('./pages/relatorio/repasse/repasse.module').then(m => m.RepasseModule)
            },
            {
                path: '**',
                loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
            }
            // {
            //   path: 'documentacao',
            //   loadChildren: () => import('./pages/documentacao/documentacao.module').then(m => m.DocumentacaoModule)
            // },
        ]
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    },
    {
        path: '**',
        loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
    }
];
