import { Animations } from './../../shared/animations/custom-animation';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  animations: [Animations.modalAnim]
})

export class ConfirmModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() confirmText: string;
  @Input() cancelText: string;
  public image: any;
  constructor(public activeModal: NgbActiveModal, public ngbModal: NgbModal) { }
}
