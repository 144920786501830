export enum TipoEntidadeEnum {
    ADMINISTRADORA = 'ADMINISTRADORA',
    CONVENIO = 'CONVENIO',
    FEDERACAO = 'FEDERACAO',
    SINDICATO = 'SINDICATO',
    SUBSEDE = 'SUBSEDE'
}

export const TipoEntidadeMap = new Map<string, any>([
    [TipoEntidadeEnum.ADMINISTRADORA, { id: 1, descricao: 'Administrador', enum: TipoEntidadeEnum.ADMINISTRADORA }],
    [TipoEntidadeEnum.CONVENIO, { id: 2, descricao: 'Convênio', enum: TipoEntidadeEnum.CONVENIO }],
    [TipoEntidadeEnum.FEDERACAO, { id: 3, descricao: 'Federação', enum: TipoEntidadeEnum.FEDERACAO }],
    [TipoEntidadeEnum.SINDICATO, { id: 4, descricao: 'Sindicato', enum: TipoEntidadeEnum.SINDICATO }],
    [TipoEntidadeEnum.SUBSEDE, { id: 5, descricao: 'Sub-Sede', enum: TipoEntidadeEnum.SUBSEDE }],
]);
