import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[focusInvalidField]'
})
export class FocusInvalidFieldDirective {

    constructor(private el: ElementRef) { }

    @HostListener('submit')
    onFormSubmit() {
        const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidElements.length > 1) {
            invalidElements[1].focus();
        }
    }
}