import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'ellipsis'})
export class EllipsisPipe implements PipeTransform {

    transform(value: string, size = 22): any {

        if(!value) return '-';
        if(value.length < size) return value;
        
        return `${value.substring(0, size -3)}...`;
    }
}