import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[apenasNumeros]'
})
export class ApenasNumerosDirective {

    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = (event.target as HTMLInputElement);
        let value = input.value;
        value = value.replace(/[^0-9]+/g, '');
        input.value = value;
    }

}
