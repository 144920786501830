// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true
};

export const SERVER_API = {
    URL: 'https://back-prd-pedidos.portalrntrc.com.br',
    PORT: 443
};

export const SERVER_ADMIN_API = {
    URL: 'https://back-prd-admin.portalrntrc.com.br',
    PORT: 443
};

export const SERVER_SECURITY_API = {
    URL: 'https://back-prd-security.portalrntrc.com.br',
    PORT: 443
};

export const HOSTNAME = {
    URL_API: `${SERVER_API.URL}:${SERVER_API.PORT}`,
    URL_API_ADMIN: `${SERVER_ADMIN_API.URL}:${SERVER_ADMIN_API.PORT}`,
    URL_API_SECURITY: `${SERVER_SECURITY_API.URL}:${SERVER_SECURITY_API.PORT}`
};

export const URL_HELP_API = {
    AUTENTICAR: {
        GENERATE_TOKEN: `${HOSTNAME.URL_API}/autenticar/generate-token`,
        GET_TOKEN_V2: `${HOSTNAME.URL_API_SECURITY}/oauth/token`
    },
    ESTADOS: {
        LISTA: `${HOSTNAME.URL_API}/estados`
    },
    FEED: {
        BASE: `${HOSTNAME.URL_API_ADMIN}/noticias`,
        ASSINATURAS: `${HOSTNAME.URL_API_ADMIN}/noticias/assinaturas`
    },
    INSTITUICOES_FINANCEIRAS: {
        LISTA: `${HOSTNAME.URL_API}/instituicaofinanceira`
    },
    PEDIDO: {
        BASE: `${HOSTNAME.URL_API}/pedido`,
        CONSULTA: `${HOSTNAME.URL_API}/pedido/analisar-abertura`,
        CONSULTA_PAGINATED: `${HOSTNAME.URL_API}/pedidos`,
        CANCELADOS: `${HOSTNAME.URL_API}/pedidos/cancelados`,
        PENDENTES: `${HOSTNAME.URL_API}/pedidos/pendentes`,
        TOTAIS: `${HOSTNAME.URL_API}/pedidos/totais`
    },
    CONCILIACAO: {
        BASE: `${HOSTNAME.URL_API}/conciliacao-repasses`,
        CONCILIAR: `${HOSTNAME.URL_API}/conciliacao-repasses/conciliar`,
        LISTA_ENTIDADES: `${HOSTNAME.URL_API}/conciliacao-repasses/entidades`,
        ARQUIVOS_GERADOS: `${HOSTNAME.URL_API}/conciliacao-repasses/arquivos-gerados`,
        ARQUIVOS_GERADOS_DOWNLOAD: `${HOSTNAME.URL_API}/conciliacao-repasses/arquivos-gerados/download`,
        GERAR_ARQUIVO_TED: `${HOSTNAME.URL_API}/conciliacao-repasses/gerar-ted`,
        RETORNO_TED: `${HOSTNAME.URL_API}/conciliacao-repasses/retorno-ted`,
        JUSTIFICATIVAS: `${HOSTNAME.URL_API}/conciliacao-repasses/justificativas`,
        RETORNO_TED_APROVAR: `${HOSTNAME.URL_API}/conciliacao-repasses/retorno-ted/aprovar`
    },
    ARRENDAMENTO: {
        BASE: `${HOSTNAME.URL_API}/arrendamento`,
        CRIAR: `${HOSTNAME.URL_API}/arrendamento/criar`,
        LISTAR: `${HOSTNAME.URL_API}/arrendamento/historico`
    },
    PESSOAS: {
        BASE: `${HOSTNAME.URL_API}/pessoas`
    },
    VEICULOS: {
        BUSCA_PLACA: `${HOSTNAME.URL_API}/veiculos`,
        BUSCA_HISTORICO: `${HOSTNAME.URL_API}/veiculos/historico`,
        LISTA_CARROCERIAS: `${HOSTNAME.URL_API}/veiculos/carrocerias`,
        LISTA_CATEGORIAS: `${HOSTNAME.URL_API}/veiculos/categorias`,
        LISTA_MARCAS: `${HOSTNAME.URL_API}/veiculos/marcas`,
        LISTA_POSSES: `${HOSTNAME.URL_API}/veiculos/posses`
    },
    TAXAS: {
        URL: `${HOSTNAME.URL_API_ADMIN}/taxas`
    },
    TAXASV2: {
        URL: `${HOSTNAME.URL_API_ADMIN}/v2/taxas`
    },
    TRANSPORTADORES: {
        BASE: `${HOSTNAME.URL_API}/transportadores`
    },
    TRANSPORTADOR: {
        BASE: `${HOSTNAME.URL_API}/transportador`,
        PEDIDO_TRANSPORTADOR: `/pedido/check-pedido`
    },
    CORREIOS: {
        BASE: `${HOSTNAME.URL_API}/correios`,
        BASE_ADMIN: `${HOSTNAME.URL_API_ADMIN}/endereco`
    },
    USUARIOSCONECTADOS: {
        REVOKE: `${HOSTNAME.URL_API_SECURITY}/token/revoke`,
        LISTA: `${HOSTNAME.URL_API_SECURITY}/usuarios/ativos`
    },
    ENTIDADES: {
        URL:                `${HOSTNAME.URL_API_ADMIN}/entidade`,
        LISTA:              `${HOSTNAME.URL_API_ADMIN}/entidade`,
        HIERARQUIA:         `${HOSTNAME.URL_API_ADMIN}/entidade/arvore-entidade`,
        ATIVAR_INATIVAR:    `${HOSTNAME.URL_API_ADMIN}/entidade/ativar-inativar`,
        PLANILHA_TAXA:      `${HOSTNAME.URL_API_ADMIN}/entidade/planilha-taxas`
    },
    USUARIOS: {
        URL: `${HOSTNAME.URL_API_SECURITY}/usuarios`,
        ATIVAR_INATIVAR: `${HOSTNAME.URL_API_SECURITY}/usuarios/ativar-inativar`,
        LISTA_USUARIOS: `${HOSTNAME.URL_API_SECURITY}/usuarios`,
        VALIDATE_USUARIOS: `${HOSTNAME.URL_API_SECURITY}/usuarios/validate-usuario`,
        CADASTRO_SENHA: `${HOSTNAME.URL_API_SECURITY}/usuarios/cadastro-senha`,
        RECADASTRO_SENHA: `${HOSTNAME.URL_API_SECURITY}/usuarios/recadastro-senha`,
        VALIDAR_SENHA_ANTT: `${HOSTNAME.URL_API_SECURITY}/usuarios/validar-senha`
    },
    PERFIS: {
        URL: `${HOSTNAME.URL_API_SECURITY}/perfis`,
        PERFIL_PADRAO: `${HOSTNAME.URL_API_SECURITY}/perfis/padrao`
    },
    FUNCIONALIDADES: {
        URL: `${HOSTNAME.URL_API_SECURITY}/funcionalidades`
    },
    TIPOSENTIDADE: {
        URL: `${HOSTNAME.URL_API_SECURITY}/tipo-entidade`
    },
    ENDERECO: {
        URL: `${HOSTNAME.URL_API_ADMIN}/endereco`
    },
    CONTAS_BANCARIAS: {
        URL: `${HOSTNAME.URL_API_ADMIN}/conta-bancaria`,
        LISTAR: `${HOSTNAME.URL_API_ADMIN}/conta-bancaria/listar`,
        BANCOS: `${HOSTNAME.URL_API_ADMIN}/conta-bancaria/bancos`
    },
    REPASSES: {
        CONVENIO: `${HOSTNAME.URL_API}/relatorio-repasse/convenio`,
        ADMINISTRACAO: `${HOSTNAME.URL_API}/relatorio-repasse/administradora`,
        FEDERACAO: `${HOSTNAME.URL_API}/relatorio-repasse/federacao`,
        SINDICATO: `${HOSTNAME.URL_API}/relatorio-repasse/sindicato`,
        DOWLOAD_PLANILHA: `${HOSTNAME.URL_API}/relatorio-repasse/download/planilha`
    },
    RELATORIO: {
        MESES: `${HOSTNAME.URL_API}/relatorio-repasse/quatro-meses`,
        CONVENIO: `${HOSTNAME.URL_API}/relatorio-repasse`
    },
    LOG: {
        BASE: `${HOSTNAME.URL_API}/historico-logs`,
        PAGED: `${HOSTNAME.URL_API}/historico-logs/paged`
    },
    MAPA: {
        GEOLOCALIZACAO: 'https://nominatim.openstreetmap.org/reverse'
    },
    LOG_ACESSO: {
        BASE: `${HOSTNAME.URL_API}/log-acesso`,
        PLANILHA: `${HOSTNAME.URL_API}/log-acesso/planilha`
    },
    UPLOAD: {
        LISTAR: `${HOSTNAME.URL_API}/transportadores/arquivos/listar`,
        UPLOAD: `${HOSTNAME.URL_API}/transportadores/arquivos/upload`,
        DOWNLOAD: `${HOSTNAME.URL_API}/transportadores/arquivos/download`,
        EXCLUSAO: `${HOSTNAME.URL_API}/transportadores/arquivos/remove`
    },
    ENTIDADE_CREDITOS: {
        BASE: `${HOSTNAME.URL_API}/entidade-credito`,
        COMPRAR: `${HOSTNAME.URL_API}/entidade-credito/comprar`,
        DETALHE: `${HOSTNAME.URL_API}/entidade-credito/detalhe`,
        EXTRATO: `${HOSTNAME.URL_API}/entidade-credito/extrato`,
        HISTORICO: `${HOSTNAME.URL_API}/entidade-credito/historico`,
        SALDO: `${HOSTNAME.URL_API}/entidade-credito/saldo`,
        VALOR_FATURA: `${HOSTNAME.URL_API}/entidade-credito/valor-fatura/creditos`,
        EXTRATO_DOWNLOAD: `${HOSTNAME.URL_API}/entidade-credito/extrato/download`,
        HISTORICO_DOWNLOAD: `${HOSTNAME.URL_API}/entidade-credito/historico/download`,
        ANTECIPACAO: `${HOSTNAME.URL_API}/entidade-credito/antecipacao`
    }
};
