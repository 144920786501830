import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent {
    public showMinisidebar = false;

    public innerWidth: any;

    public config: PerfectScrollbarConfigInterface = {
        swipeEasing: true,
        wheelSpeed: 1,
        wheelPropagation: true,
    };

    constructor(public readonly router: Router) { }

    public toggleSidebar(): void {
        this.showMinisidebar = !this.showMinisidebar;
    }

    public getStyle(): string {
        return window.window.innerHeight - 62 + 'px';
    }
}
