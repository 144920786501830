import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from 'app/app.injector.service';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(
        (moduleRef) => {
            AppInjector.setInjector(moduleRef.injector);
        })
    .catch(
        (err) => console.log(err));
