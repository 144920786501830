import { Component, OnInit } from '@angular/core';
import { ModalService } from './../../../../../services/modal.service';
import { PedidoCanceladoDetalheModel } from '../../models/PedidoCanceladoDetalheModel';
import { of } from 'rxjs';
import { IsLoadingService } from './../../../../../services/is-loading.service';
import { ResponseBase } from './../../../../../interfaces/response-base';
import { PedidoService } from '../../../../../services/pedido.service';
import { Pedido } from './../../../../../models/pedido/pedido';
import { PedidoCanceladoResponse } from '../../models/PedidoCanceladoResponse';
import { TipoPedidoEnumLabel, TipoPedidoEnum } from '../../../../../models/pedido/tipo-pedido-enum';
import { Pagamento } from '../../../../../models/pagamento/pagamento.model';
import { TransportadorService } from '../../../../../services/transportador.service';
import { StatusPedidoModel } from '../../../../pedido/status/model/status-pedido.model';
import { PedidoPagamentoService } from '../../../../../services/pedido-pagamento.service';

@Component({
  selector: 'app-modal-detalhe-registro',
  templateUrl: './modal-detalhe-registro.component.html',
  styleUrls: ['./modal-detalhe-registro.component.css']
})
export class ModalDetalheRegistroComponent implements OnInit {

  // public pedidoCanceladoDetalheModel: PedidoCanceladoDetalheModel;
  public item: PedidoCanceladoResponse;
  public codigoPedido: number;

  constructor(
    private modalService: ModalService,
    private pedidoService: PedidoService,
    private transportadorService: TransportadorService,
  ) {
  }

  close() {
    this.modalService.hideComponent();
  }


  ngOnInit() {
    this.item.tipoPedido = TipoPedidoEnumLabel.get(this.item.tipoPedido).descricao;

    this.transportadorService.detalhe(this.item.cpfCnpjTransportador).then((item: any) => {
      this.item.rntrc = item.data.numeroRntrc;
      this.item.statusRntrc = item.data.situacaoRntrc;
    }).catch(() => {
      this.item = null;
      this.modalService.event.subscribe(() => {
        this.close();
      });
    });

    this.pedidoService.consultarPagamentoPedido(this.item.codigo).then((item: ResponseBase<StatusPedidoModel>) => {
      this.item.numeroPedidoAntt = item.data.codigoPedido.toString();
      this.item.statusPagamento = item.data.status;
    }).catch(() => {
      this.item.numeroPedidoAntt = 'Não possui acesso';
      this.item.statusPagamento = 'N/D';
    });
  }
}
