import { state, trigger, style, transition, animate, query, group } from '@angular/animations';

export class Animations {
    public static readonly openClosePedidoConsulta = trigger('openClose', [
        state('open', style({
            opacity: 1,
            height: '100%',
            transform: 'translateY(0)',
        })),
        state('closed', style({
            opacity: 0,
            height: '0px',
            transform: 'translateY(-50px)'
        })),
        transition('open => closed', [
            animate('0.2s ease-out')
        ]),
        transition('closed => open', [
            animate('0.2s ease-out')
        ]),
    ]);


    public static readonly openCloseAnimation = trigger('openClose', [
        state('open', style({
            opacity: 1,
            height: '100px',
            transform: 'translateY(0)',
        })),
        state('closed', style({
            opacity: 0,
            height: '0px',
            transform: 'translateY(-50px)'
        })),
        transition('open => closed', [
            animate('0.2s ease-out')
        ]),
        transition('closed => open', [
            animate('0.2s ease-out')
        ]),
    ]);

    public static readonly fade = trigger('fade', [
        state('open', style({
            opacity: 1,
            zIndex: 1
        })),
        state('closed', style({
            opacity: 0,
            zIndex: -1
        })),
        transition('open => closed', [
            animate('0.2s ease-out')
        ]),
        transition('closed => open', [
            animate('0.2s ease-out')
        ]),
    ]);

    public static readonly openCloseFullHeightAnimation = trigger('openCloseFullHeightAnimation', [
        state('open', style({
            opacity: 1,
            height: '100%',
            transform: 'translateY(0px)',
        })),
        state('closed', style({
            opacity: 0,
            height: '0px',
            transform: 'translateY(0px)'
        })),
        transition('open => closed', [
            animate('0.2s ease-out')
        ]),
        transition('closed => open', [
            animate('0.2s ease-out')
        ]),
    ]);

    public static readonly inOutAnimation = trigger(
        'inOutAnimation',
        [
            transition(
                ':enter',
                [
                    style({ transform: 'translateX(0px)', opacity: 1 }),
                    animate('0.3s ease-out',
                        style({ transform: 'translateX(500px)', opacity: 0 }))
                ]
            )
        ]
    );
    public static readonly modalAnim =
        trigger('modalAnim',
            [
                transition(
                    ':enter',
                    [
                        style(
                            {
                                // rotateX(35deg) scale3d(0.1,0.1,0.1)
                                transform: 'rotateY(45deg) rotateX(60deg)',
                                opacity: 0
                            }
                        ),
                        animate('0.3s ease-out',
                            style(
                                {
                                    // rotateX(0deg) scale3d(1,1,1)
                                    transform: 'rotateY(0deg) rotateX(0deg)',
                                    opacity: 1
                                }
                            ))
                    ]),
                transition(
                    ':leave',
                    [
                        animate('0.3s ease-out',
                            style(
                                {
                                    // rotateX(35deg) scale3d(0.1,0.1,0.1)
                                    transform: 'rotateY(45deg) rotateX(60deg)',
                                    opacity: 0
                                }
                            ))
                    ],
                )
            ]
        );

    public static readonly routerTransition = trigger('routerTransition', [
        transition('* <=> *', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' })
                , { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateX(100%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
                ], { optional: true }),

                query(':leave', [
                    style({ transform: 'translateX(0%)' }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
                ], { optional: true })
            ])
        ])
    ]);


    public static fieldError = trigger(
        'fieldError',
        [
            transition(
                ':leave',
                [
                    style({ transform: 'translateX(0px)', opacity: 1 }),
                    animate('0.2s ease-out',
                        style({ transform: 'translateX(400px)', opacity: 0 }))
                ]
            )
        ]
    );
}
