import { PaginatedModel } from './paginated.model';
import { PaginatedOptions } from './paginated-options';

export class PaginatedFilter extends PaginatedOptions {
    constructor(public options: PaginatedModel) {
        super(options);
    }
    public filterKeys = () => {
        return `?${
            Object.entries(this.options)
                .map(i => i.toString())
                .map(i => i.replace(',', '='))
                .join('&')}`;
    }
}
