import { Pagamento } from './../models/pagamento/pagamento.model';
import { StatusPedidoModel } from './../pages/pedido/status/model/status-pedido.model';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { ModalService } from './modal.service';
import { IsLoadingService } from './is-loading.service';
import { URL_HELP_API } from 'environments/environment';
import { Pedido } from 'app/models/pedido/pedido';
import { RnTransportador } from 'app/models/transportador/rn-transportador';
import { of } from 'rxjs/internal/observable/of';
import { ResponseBase, PaginatedResponse } from 'app/interfaces/response-base';
import { PaginatedFilter } from '../models/pagination/paginated-filter';
import { ConsultaModel } from '../pages/pedido/models/consultaModel';
import { Veiculo } from 'app/models/veiculo/veiculo';
import { Evento } from 'app/models/evento/evento.model';

@Injectable({
    providedIn: 'root'
})
export class PedidoService extends BaseService<any> {

    constructor(
        protected httpClient: HttpClient,
        protected modalService: ModalService,
        protected isLoadingService: IsLoadingService
    ) {
        super(httpClient, modalService, isLoadingService);
    }

    detalhe(codigoPedido: number): Promise<ResponseBase<Pedido>> {
        return new Promise<ResponseBase<Pedido>>((resolve, reject) => {
            this.get<ResponseBase<Pedido>>(`${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}`)
                .subscribe(
                    (data: ResponseBase<Pedido>) => {
                        resolve(data);
                    },
                    (err: any) => {
                        this.error(err), reject(err);
                    }
                );
        });
    }

    pendentesCancelados(): Promise<ResponseBase<any>> {
        return new Promise<ResponseBase<any>>((resolve, reject) => {
            this.get<ResponseBase<any>>(`${URL_HELP_API.PEDIDO.TOTAIS}`)
                .subscribe(
                    (data: ResponseBase<Pedido>) => {
                        resolve(data);
                    },
                    (err: any) => {
                        this.error(err), reject(err);
                    }
                );
        });
    }

    detalheBase(codigoPedido: number): Promise<ResponseBase<Pedido>> {
        return new Promise<ResponseBase<Pedido>>((resolve, reject) => {
            this.get<ResponseBase<Pedido>>(`${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/base`)
                .subscribe(
                    (data: ResponseBase<Pedido>) => {
                        resolve(data);
                    },
                    (err: any) => {
                        this.error(err), reject(err);
                    }
                );
        });
    }

    consulta(documento: string, tipo: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.get(`${URL_HELP_API.PEDIDO.CONSULTA}/${documento}`)
                .subscribe(
                    (data: any) => {
                        resolve(data);
                    },
                    (err: any) => {
                        this.error(err), reject(err);
                    }
                );
        });
    }

    criar(documento: string, tipo: string) {
        return new Promise<any>((resolve, reject) => {
            this.post(
                URL_HELP_API.PEDIDO.BASE,
                {
                    cpfCnpjTransportador: documento,
                    tipoPedido: tipo
                })
                .subscribe((result) => {
                    resolve(result);
                }, (err) => {
                    this.error(err), reject(err);
                });
        });
    }

    atualizar(pedido: any) {
        return new Promise<any>((resolve, reject) => {
            this.put(
                `${URL_HELP_API.PEDIDO.BASE}/${pedido.codigoPedido}`,
                pedido
            ).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    this.error(err), reject(err);
                });
        });
    }

    cancelar(codigoPedido: number, rowVersion: string, situacaoPedido?: string) {
        let params = new HttpParams();
        
        if (!!situacaoPedido) {
            params = params.set('situacaoPedido', situacaoPedido);
        }

        return new Promise<any>((resolve, reject) => {
            this.httpClient.patch(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/cancelar/${rowVersion}`,
                null,
                { params })
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    migrar(codigoPedido: number) {
        return new Promise<any>((resolve, reject) => {
            this.post(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/migrar`
            ).subscribe(
                result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                }
            );

        });
    }

    finalizar(codigoPedido: number, rowVersion: string) {
        return new Promise<any>((resolve, reject) => {
            this.patch(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/finalizar/${rowVersion}`)
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    finalizarSemValor(codigoPedido: number) {
        return new Promise<any>((resolve, reject) => {
            this.patch(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/finalizar`)
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    public eventos(codigoPedido: number): Promise<ResponseBase<Array<Evento>>> {
        return new Promise<ResponseBase<Array<Evento>>>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/eventos`)
                .subscribe((result: ResponseBase<Array<Evento>>) => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    validar(codigoPedido: number) {
        return new Promise<any>((resolve, reject) => {
            this.post(
                `${URL_HELP_API.PEDIDO.BASE}/${codigoPedido}/validar`, {})
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    analisarAbertura(documento: string, tipoBusca: string, tipoPedido: string) {
        return new Promise<any>((resolve, reject) => {
            this.get(
                `${URL_HELP_API.PEDIDO.BASE}/analisar-abertura/${documento}?tipoBusca=${tipoBusca}&tipoPedido=${tipoPedido}`)
                .subscribe(result => {
                    resolve(result);
                }, err => {
                    this.error(err), reject(err);
                });
        });
    }

    public consultarPedido(id: string | number): Promise<ResponseBase<StatusPedidoModel>> {
        return new Promise<ResponseBase<StatusPedidoModel>>((accept, reject) => {
            this.get(`${URL_HELP_API.PEDIDO.BASE}/${id}`)
                .subscribe(
                    (res: ResponseBase<StatusPedidoModel>) => {
                        accept(res);
                    },
                    (err: HttpErrorResponse) => {
                        this.error(err);
                        reject(err);
                    });
        });
    }
    public consultarPedidosPaginated(uri: string): Promise<ResponseBase<PaginatedResponse<ConsultaModel>>> {
        return this.getBase(`${URL_HELP_API.PEDIDO.CONSULTA_PAGINATED}?${uri}`);
    }

    public consultarVeiculosPedido(pedidoId: string | number): Promise<ResponseBase<Array<Veiculo>>> {
        return new Promise<ResponseBase<Array<Veiculo>>>((accept, reject) => {
            this.get(`${URL_HELP_API.PEDIDO.BASE}/${pedidoId}/veiculos`)
                .subscribe(
                    (res: ResponseBase<Array<Veiculo>>) => {
                        accept(res);
                    },
                    (err: HttpErrorResponse) => {
                        this.error(err);
                        reject(err);
                    }
                );
        });
    }

    public consultarPagamentoPedido(pedidoId: string | number): Promise<ResponseBase<Pagamento>> {
        return new Promise<ResponseBase<Pagamento>>((accept, reject) => {
            this.get(`${URL_HELP_API.PEDIDO.BASE}/${pedidoId}/dados-pagamento`)
                .subscribe(
                    (res: ResponseBase<Pagamento>) => {
                        accept(res);
                    },
                    (err: HttpErrorResponse) => {
                        this.error(err);
                        reject(err);
                    }
                );
        });
    }

    buscarVeiculos(codPedido: number): Promise<ResponseBase<Veiculo>> {
        return new Promise<ResponseBase<Veiculo>>((resolve, reject) => {
            this.get(`${URL_HELP_API.PEDIDO.BASE}/${codPedido}/veiculos`)
                .subscribe(
                    (data: ResponseBase<Veiculo>) => {
                        resolve(data);
                    },
                    err => { this.error(err), reject(err); });
        });
    }
}
