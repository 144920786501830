import { PaginatedModel } from './paginated.model';
export class PaginatedOptions {
    public readonly paginatedModel: PaginatedModel;

    constructor(paginatedModel: PaginatedModel) {
        this.paginatedModel = paginatedModel;
    }

    public getUriString() {

        Object.entries(this.paginatedModel).map(i => console.log('i0 - i1', i[0], i[1]));
    }

}
