import { Directive, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Directive({selector: '[maskMoney]'})
export class MaskMoneyDirective implements OnInit {

    previousValue: string;

    @Input()
    maskMoney: MaskMoneyOption = {};

    @Output()
    getValue = new EventEmitter<string>();

    @Output()
    getValueAsNumber = new EventEmitter<number>();

    ngOnInit() {
        this.maskMoney = {
            decimal: this.maskMoney.decimal !== undefined ? this.maskMoney.decimal : 2,
            integerDivisor: this.maskMoney.integerDivisor ? this.maskMoney.integerDivisor : '.',
            decimalDivisor: this.maskMoney.decimalDivisor ? this.maskMoney.decimalDivisor : ',',
            numbersByDivisor: this.maskMoney.numbersByDivisor !== undefined ? this.maskMoney.numbersByDivisor : 3,
            numbersLimiter: this.maskMoney.numbersLimiter ? this.maskMoney.numbersLimiter : Infinity,
            maxValue: this.maskMoney.maxValue ? this.maskMoney.maxValue : Infinity,
            allowZero: this.maskMoney.allowZero ? this.maskMoney.allowZero : false,
            suffix: this.maskMoney.suffix ? `${this.maskMoney.suffix} ` : '',
            prefix: this.maskMoney.prefix ? `${this.maskMoney.prefix} ` : ''
        };
    }

    @HostListener('input', ['$event'])
    input(event) {
        const input = (event.target as HTMLInputElement);
        let value = input.value;
        
        if (value === '') {
            input.value = value;
            this.getValue.emit(value);
            this._emitValueAsNumber(value);
            return;
        }

        value = MaskMoneyDirective._holdJustNumbers(value);

        if (this._isMaxIntValueReached(value)) {
            value = value.substr(0, value.length - 1);
        }

        while (value.charAt(0) === '0') {
            value = value.substr(1);
        }

        if (this.maskMoney.allowZero && !value) {
            value = '0';
        }

        if (!this.maskMoney.allowZero && !value) {
            input.value = value;
            this.getValue.emit(value);
            this._emitValueAsNumber(value);
            return;
        }

        const decimalValue = this._getDecimalValue(value);

        if (decimalValue) {
            const token = decimalValue.split(this.maskMoney.decimalDivisor);

            if (token[0].length > this.maskMoney.numbersByDivisor) {
                token[0] = this._getIntValue(token[0]);
                value = token.join(this.maskMoney.decimalDivisor);
            } else {
                value = decimalValue;
            }

        } else if (value.length > this.maskMoney.numbersByDivisor) {
            value = this._getIntValue(value);
        }

        value = this._getValueFromCheckMaxValue(value);

        input.value = `${this.maskMoney.prefix}${value}${this.maskMoney.suffix}`;
        this.getValue.emit(value);
        this._emitValueAsNumber(value);
    }

    private _getDecimalValue(value: string): string {
        if (this.maskMoney.decimal) {
            if (value.length <= this.maskMoney.decimal) {
                return '0' + this.maskMoney.decimalDivisor + '0'.repeat(this.maskMoney.decimal - value.length) + value;

            } else {
                let v = value.substr(0, value.length - this.maskMoney.decimal) + '.' + value.substr(value.length - this.maskMoney.decimal);
                let vNumber = parseFloat(v);

                if (vNumber > this.maskMoney.maxValue) return this.previousValue;

                return value.substr(0, value.length - this.maskMoney.decimal) + this.maskMoney.decimalDivisor + value.substr(value.length - this.maskMoney.decimal);
            }
        }
        return '';
    }

    private _getIntValue(value: string): string {
        let dif = 0;
        let left = '';
        const chunks: string[] = [];
        let multiplier = Math.floor(value.length / this.maskMoney.numbersByDivisor);

        if (multiplier) {
            dif = value.length - (multiplier * this.maskMoney.numbersByDivisor);
        }

        if (dif) {
            left = value.substr(0, dif) + this.maskMoney.integerDivisor;
            value = value.substr(dif);
        }

        while (multiplier) {
            chunks.push(value.substr(0, this.maskMoney.numbersByDivisor));
            value = value.substr(this.maskMoney.numbersByDivisor);
            multiplier--;
        }

        return left + chunks.join(this.maskMoney.integerDivisor);
    }

    private _isMaxIntValueReached(value: string): boolean {
        return value.length > this.maskMoney.numbersLimiter;
    }

    private _getValueFromCheckMaxValue(value: string) {
        let token = value.split(this.maskMoney.decimalDivisor);
        const v = MaskMoneyDirective._holdJustNumbers(token[0]);
        const n = parseInt(v);

        if (n <= this.maskMoney.maxValue) {
            this.previousValue = value;
            return value;
        }

        return this.previousValue;
    }

    private static _holdJustNumbers(value: string): string {
        return value.replace(/[^0-9]+/g, '');
    }

    private _emitValueAsNumber(value: string): void {
        if (this.maskMoney.integerDivisor === this.maskMoney.decimalDivisor) {
            console.log('It was possible convert value to number cause decimalDivisor and integerDivisor are equal');
            this.getValueAsNumber.emit(NaN);
        }

        value = value.replace(this.maskMoney.decimalDivisor, '@@@');
        value = value.replace(/\./g, '');
        value = value.replace(this.maskMoney.prefix, '');
        value = value.replace(this.maskMoney.suffix, '');
        value = value.replace(/,/g, '');
        value = value.replace('@@@', '.');

        const valueAsNumber = parseFloat(value);
        this.getValueAsNumber.emit(valueAsNumber);
    }

}

export interface MaskMoneyOption {
    decimal?: number;
    numbersLimiter?: number;
    maxValue?: number;
    integerDivisor?: '.' | ',';
    decimalDivisor?: '.' | ',';
    numbersByDivisor?: number;
    allowZero?: boolean;
    suffix?: string;
    prefix?: string;
}
