import * as moment from 'moment';

export class DateFormat {
    public static readonly defaultFormat = 'YYYY-MM-DD';

    public static readonly getCurrentDate = moment(new Date()).format(DateFormat.defaultFormat);

    public static getMomentDate(date: Date | string, format?: string):
        string { return moment(date, this.defaultFormat).format(format || this.defaultFormat); }

    public static getCustomMomentFormat(date: string, format?: string):
        string { return moment(date).format(format || this.defaultFormat); }
}
