import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Animations } from 'app/shared/animations/custom-animation';
@Component({
    template: `
    <div *ngIf="!_hide">
        <span class="invalid-field" style="position: absolute; width: 100%;" [@fieldError]>
            <p>{{_text || ''}} <i class="mdi mdi-alert"></i></p>
        </span>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Animations.fade, Animations.fieldError],
})
export class ControlErrorComponent {
    _text: string;
    _hide = true;

    @Input() set text(value: string) {
        if (value !== this._text) {
            this._text = value;
            this._hide = !value;
            this.cdr.detectChanges();
        }
    }

    constructor(private cdr: ChangeDetectorRef) { }
}