export enum TipoPedidoEnum {
    MovimentacaoFrota = 'MovimentacaoFrota',
    Cadastro = 'Cadastro',
    Reativacao = 'Reativacao',
    AlteracaoDados = 'AlteracaoDados',
    RevalidacaoOrdinaria = 'RevalidacaoOrdinaria'
}

export const TipoPedidoEnumLabel = new Map<string, any>([
    [TipoPedidoEnum.AlteracaoDados, { id: 1, descricao: 'Alteracão de Dados', enum: TipoPedidoEnum.AlteracaoDados }],
    [TipoPedidoEnum.Cadastro, { id: 2, descricao: 'Cadastro', enum: TipoPedidoEnum.Cadastro }],
    [TipoPedidoEnum.MovimentacaoFrota, { id: 3, descricao: 'Movimentação de Frota', enum: TipoPedidoEnum.MovimentacaoFrota }],
    [TipoPedidoEnum.Reativacao, { id: 4, descricao: 'Reativação', enum: TipoPedidoEnum.Reativacao }],
    [TipoPedidoEnum.RevalidacaoOrdinaria, { id: 5, descricao: 'Revalidação Ordinária', enum: TipoPedidoEnum.RevalidacaoOrdinaria }],
]);
