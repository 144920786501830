import { InjectionToken } from '@angular/core';
import { FormError } from './form-error.model';

export const defaultErrors = {
    required: (): FormError => ({
        description: `O campo é obrigatório`
    }),

    minlength: ({ requiredLength, currentLength }): FormError => ({
        description: `O campo precisa ter no mínimo ${requiredLength} caracteres.`,
    }),

    maxlength: ({ requiredLength, currentLength }): FormError => ({
        description: `O campo precisa ter no máximo ${requiredLength} caracteres.`,
    }),

    cpfvalidator: (): FormError => ({
        description: `Cpf informado inválido`,
        beforeValidation: 11
    }),

    cnpjvalidator: (): FormError => ({
        description: `Cnpj informado inválido`,
        beforeValidation: 14
    }),

    matDatepickerParse: (): FormError => ({
        description: `Informe uma data válida`
    }),

    min: ({ min, actual }): FormError => ({
        description: `Valor menor que ${min}`
    })
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});