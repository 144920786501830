import { Injectable, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/components/modal/modal.component';

@Injectable({
    providedIn: 'root'
})

export class ModalService {
    private isOpen = false;

    constructor(private ngbModal: NgbModal) { }

    public event = new EventEmitter<{ val: boolean, type: string }>();

    error(message: string, title: string = 'Erro', confirmText: string = 'OK', size: string = 'default'):
        void {
        this.open(message, title, confirmText, size, 'mdi mdi-close-circle-outline error', 'error');
    }

    important(message: string, title: string = 'Alerta', confirmText: string = 'OK', size: string = 'default'):
        void {
        this.open(message, title, confirmText, size, 'mdi mdi-alert-circle-outline important', 'important');
    }

    info(message: string, title: string = 'Informação', confirmText: string = 'OK', size: string = 'default'):
        void {
        this.open(message, title, confirmText, size, 'mdi mdi-information-outline info', 'info');
    }

    success(message: string, title: string = 'Sucesso', confirmText: string = 'OK', size: string = 'default'):
        void {
        this.open(message, title, confirmText, size, 'mdi mdi-check-circle-outline success', 'success');
    }

    showComponent(component: any, windowClass: string = 'dialog') {
        return this.ngbModal.open(component, { windowClass });
    }

    hideComponent() {
        this.ngbModal.dismissAll();
    }

    private open(message: string, title: string, confirmText: string = 'OK', size: string, icon: string, type: string): void {
        if (!this.isOpen) {

            const modalRef = this.ngbModal.open(ModalComponent, {
                windowClass: size,
                backdrop: 'static'
            });

            modalRef.componentInstance.icon = icon;
            modalRef.componentInstance.title = title;
            modalRef.componentInstance.message = message;
            modalRef.componentInstance.confirmText = confirmText;
            modalRef.componentInstance.type = type;

            modalRef.componentInstance.event.subscribe((res: { val: boolean, type: string }) => {
                this.event.emit(res);
                this.isOpen = false;
            });

            this.isOpen = true;
        }
    }
}
