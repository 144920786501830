import { AuthenticationService } from 'app/services/authentication.service';
import { LoginService } from './../../services/login.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { EntidadeCreditosService } from 'app/services/entidade-creditos.service';
import { SaldoCreditosModel } from 'app/models/entidade-creditos/saldo-creditos.model';
import { ResponseBase } from 'app/interfaces/response-base';
import { PermissionLevel } from 'app/models/authentication/permission-level';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent {
    @Output() toggleSidebar = new EventEmitter<void>();

    public user: {
        name: string;
        email: string;
    };

    public creditos: number;
    public habilitaVisualizarCreditos: boolean = false;

    public config: PerfectScrollbarConfigInterface = {};
    constructor(
        private readonly loginService: LoginService,
        private readonly router: Router,
        private readonly authService: AuthenticationService,
        private readonly entidadeCreditosService: EntidadeCreditosService
    ) {
        this.user = {
            name: this.authService.userObject.nome,
            email: this.authService.userObject.email,
        };

        this.habilitaVisualizarCreditos = this.authService.hasPermission(this.authService.userObject, 'Aquisição de Créditos', 'Administração', PermissionLevel.Cadastrar);
    }

    public showSearch = false;

    logout() {
        this.loginService.logout();
        this.router.navigate(['/']);
    }

    public atualizarCreditos(evt: any): any {
        if (evt === true) {
            this.entidadeCreditosService
                .obterSaldo()
                .then((res: ResponseBase<SaldoCreditosModel>) => {
                    if(res.data != null){
                        this.creditos = res.data.creditosDisponiveis;
                    }
                });
        }
    }
}