import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ModalService } from "./../../../../../services/modal.service";
import { AnalisePedidoModel } from "./../../models/AnalisePedidoModel";
import { AnalisePedidoVeiculoModel } from "./../../models/AnalisePedidoModel";
import { forkJoin } from "rxjs";
import { IsLoadingService } from "./../../../../../services/is-loading.service";
import { ResponseBase } from "./../../../../../interfaces/response-base";
import { PedidoService } from "../../../../../services/pedido.service";
import { Pedido } from "./../../../../../models/pedido/pedido";
import { PedidoVeiculoService } from "app/services/pedido-veiculo.service";

@Component({
  selector: "app-modal-analise-concluida",
  templateUrl: "./modal-analise-concluida.component.html",
  styleUrls: ["./modal-analise-concluida.component.css"]
})
export class ModalAnaliseConcluidaComponent implements AfterViewInit, OnInit {
  public analisePedidoModel: AnalisePedidoModel;
  public codigoPedido: number;
  public temDadosPagamento: boolean;

  constructor(
    private modalService: ModalService,
    private pedidoService: PedidoService,
    private pedidoVeiculoService: PedidoVeiculoService,
    private loadingService: IsLoadingService
  ) {}

  close() {
    this.modalService.hideComponent();
  }

  ngOnInit() {
    this.analisePedidoModel = new AnalisePedidoModel();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.analisePedidoModel = new AnalisePedidoModel();
      this.loadingService.show();

      forkJoin([
        this.pedidoService.detalheBase(this.codigoPedido),
        this.pedidoService.consultarPagamentoPedido(this.codigoPedido),
        this.pedidoVeiculoService.listarVeiculosCobranca(this.codigoPedido)
      ]).subscribe(result => {
        this.loadingService.dismiss();

        // Pedido
        const resPedido: ResponseBase<Pedido> = result[0];
        if (resPedido.httpStatusCode !== 200) {
          this.modalService.info("Dados do pedido não foram encontrados");
          return;
        }

        this.analisePedidoModel.protocoloPedido = resPedido.data.codigoPedido;
        this.analisePedidoModel.tipoPedido = resPedido.data.tipoPedido;
        this.analisePedidoModel.cpfTac = resPedido.data.cpfCnpjTransportador;
        this.analisePedidoModel.nomeTac = resPedido.data.transportador.nomeTransportador;
        this.analisePedidoModel.sindicatoAbertura = resPedido.data.nomeEntidade;
        this.analisePedidoModel.usuario = resPedido.data.cpfUsuario;
        this.analisePedidoModel.nomeUsuario = resPedido.data.nomeUsuario;
        this.analisePedidoModel.statusPedido = resPedido.data.codigoSituacao;
        this.analisePedidoModel.descricaoStatus = resPedido.data.situacaoPedido;
        this.analisePedidoModel.dataAberturaPedido = resPedido.data.dataCriacao;
        this.analisePedidoModel.ultimaAtualizacao = resPedido.data.dataAtualizacao;

        // Pagamento
        const dadosPagamento = result[1];
        if (resPedido.httpStatusCode !== 200) {
          this.modalService.info("Ocorreu um erro ao obter os dados do pagamento.");
          return;
        }
        this.temDadosPagamento = dadosPagamento.data !== null;
        if(this.temDadosPagamento) {
          this.analisePedidoModel.valorPedido = (dadosPagamento.data.valorTotal !== null) ? dadosPagamento.data.valorTotal : 0;
          this.analisePedidoModel.tipoPagamento = dadosPagamento.data.forma;
          this.analisePedidoModel.valorPago = (dadosPagamento.data.dataPagtoEfetivado !== null) ? dadosPagamento.data.valorTotal : 0;
          this.analisePedidoModel.situacaoPagamento = dadosPagamento.data.status;
          this.analisePedidoModel.id = dadosPagamento.data.boletoNossoNumero;
          this.analisePedidoModel.descricaoRegistro1 = "Registro encontra-se dentro do processo financeiro";
          this.analisePedidoModel.descricaoRegistro2 = "Registro ainda não entrou para cálculo de repasse";
        }
        // Veículo
        const resVeiculo = result[2];
        if (resVeiculo.httpStatusCode === 200) {
          this.analisePedidoModel.veiculos = [];

          const retornVeiculos = Array().concat(resVeiculo.data);

          retornVeiculos.forEach(veiculo => {
            const analisePedidoVeiculoModel = new AnalisePedidoVeiculoModel();
              analisePedidoVeiculoModel.veiculo = veiculo.tipoVeiculo.toUpperCase();
              analisePedidoVeiculoModel.placa = veiculo.placa;
              this.analisePedidoModel.veiculos.push(analisePedidoVeiculoModel);
          });
        }

      }, () => this.loadingService.dismiss());
    });
  }
}
