import { RoutesDefinition } from './menu-items-definitions';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent {

    showMenu = '';
    showSubMenu = '';
    public sidebarnavItems: any[];
    private routesDefinition: RoutesDefinition;

    constructor(private readonly router: Router, protected readonly authService: AuthenticationService) {
        this.routesDefinition = new RoutesDefinition(authService);
        this.sidebarnavItems = this.routesDefinition.routes;
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }

    removeActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        }
    }

    removeAllActiveClasses(element: any[]) {
        element.map(i => {
            this.showMenu = '0';
            if (!!i.submenu) {
                this.showSubMenu = '0';
            }
        });
    }


    public access(url: string, title: string): void {
        this.removeActiveClass(title);
        if (this.router.url === url) {
            this.router.navigateByUrl('/portal/fake', { state: { redirectTo: this.router.url, do: true }, skipLocationChange: true });
            setTimeout(() => {
                this.router.navigateByUrl(url);
            }, 1);
        } else if (!!url) {
            this.router.navigateByUrl(url);
        }
    }

}
