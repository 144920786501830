import { Animations } from './../../shared/animations/custom-animation';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [Animations.modalAnim]
})
export class ModalComponent {

  @Input() icon: string;
  @Input() title: string;
  @Input() message: string;
  @Input() confirmText: string;
  @Input() type: string;
  public event: EventEmitter<{ val: boolean, type: string }> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, public ngbModal: NgbModal) { }

  close() {
    this.event.emit({ val: true, type: this.type });
    this.activeModal.close();
  }
}
