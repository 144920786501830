import { CpfMaskDirective } from './directives/cpf-mask.directive';
import { ControlErrorsDirective } from './directives/control-error/control-errors.directive';
// tslint:disable: max-line-length
import { ControlErrorComponent } from './directives/control-error/control-error.component';
import { CpfValidatorDirective } from './directives/cpf-validator.directive';
import { CpfCnpjMaskDirective } from './directives/cpf-cnpj-mask.directive';;
import { ApenasNumerosDirective } from './directives/apenas-numeros.directive';
import { MaskMoneyDirective } from './directives/mask-money.directive';
import { DragDropDirective } from './directives/drag-and-drop.directive';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { CpfPipe } from './pipes/cpf.pipe';
import { CpfCnpjPipe } from './pipes/cpfCnpj.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { RequestInterceptorService } from 'app/interceptors/request-interceptor.service';
import { TooltipComponent } from './tooltip/tooltip.component';
import { CustomMatPaginatorIntl } from './data-table/data-table.service';
import { DataTableComponent } from './data-table/data-table.component';
import { FocusInvalidFieldDirective } from './directives/focus-invalid-field';
import { ToastService } from './../services/toast.service';
import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { FullComponent } from 'app/layouts/full/full.component';

import { 
    MatTabsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule,
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatAutocompleteModule,
    DateAdapter,
    MAT_DATE_FORMATS,
    MatSlideToggleModule,
    MatListModule,
    MatDateFormats,
    MatDialogModule,
    MatIconModule } from '@angular/material';
    
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './header-navigation/navigation.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ModalComponent } from 'app/components/modal/modal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'app/components/confirm-modal/confirm-modal.component';
import { MAT_DATE_LOCALE, MatSnackBar, MatPaginatorIntl } from '@angular/material';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { SpinnerComponent } from './spinner.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QuillModule } from 'ngx-quill';
import { ModalAnaliseConcluidaComponent } from '../pages/relatorio/pedidos/pendentes/modal-analise-concluida/modal-analise-concluida.component';
import { ModalDetalheRegistroComponent } from '../pages/relatorio/pedidos/cancelados/modal-detalhe-registro/modal-detalhe-registro.component';
import { MaskDateDirective } from './directives/mask-date.directive';
import { ModalDetalhePerfilComponent } from 'app/pages/admin/perfil-acesso/detalhe/modal-detalhe-perfil.component';
import { ChangeFormDirective } from './directives/control-error/change.directive';
import { CnpjViewComponent } from 'app/pages/admin/cnpj/lista/cnpj-view/cnpj-view.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CnpjValidatorDirective } from './directives/cnpj-validator.directive';
import { BaseComponent } from 'app/pages/base.component';
import {PlacaVeiculoMaskDirective} from './directives/placa-veiculo-mask.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

const materialModules = [
    MatTabsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatListModule,
    MatIconModule,
    MatDialogModule
]

const Directives = [
    FocusInvalidFieldDirective,
    MaskDateDirective,
    CpfValidatorDirective,
    CnpjValidatorDirective,
    DragDropDirective,
    ChangeFormDirective,
    ControlErrorsDirective,
    CpfCnpjMaskDirective,
    ApenasNumerosDirective,
    MaskMoneyDirective,
    CpfMaskDirective,
    PlacaVeiculoMaskDirective
];

const Pipes = [CpfCnpjPipe, CnpjPipe, CpfPipe, EllipsisPipe];

export const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM/YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

// 'legacy' | 'standard' | 'fill' | 'outline';
@NgModule({
    declarations: [
        FullComponent,
        BaseComponent,
        SidebarComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        ModalComponent,
        ConfirmModalComponent,
        DataTableComponent,
        TooltipComponent,
        CnpjViewComponent,
        ModalAnaliseConcluidaComponent,
        ModalDetalheRegistroComponent,
        ModalDetalhePerfilComponent,
        ControlErrorComponent,
        ...Pipes,
        ...Directives
    ],
    imports: [
        CommonModule,
        materialModules,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        NgbModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        MatSnackBarModule,
        NgxMaskModule.forRoot({ validation: false }),
        NgxCurrencyModule,
        PdfViewerModule,
        QuillModule.forRoot({
            modules: {
                syntax: false,
                toolbar: {}
            }
        })
    ],
    entryComponents: [
        ControlErrorComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        materialModules,
        PerfectScrollbarModule,
        NgbModule,
        NgMultiSelectDropDownModule,
        DataTableComponent,
        NgxCurrencyModule,
        NgxMaskModule,
        TooltipComponent,
        PdfViewerModule,
        QuillModule,
        NgxPermissionsModule,
        ...Pipes,
        ...Directives,
    ],
    providers: [
        NgxMaskModule,
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'legacy'
            }
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true
        },
        ToastService,
        MatSnackBar,
        SpinnerComponent
    ]
})
export class SharedModule { }
