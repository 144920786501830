import { RouteInfo } from './../sidebar/sidebar.metadata';

export class Url {
    private readonly routes: Array<RouteInfo>;

    constructor() {
        this.routes = [
            {
                path: 'cadastro',
                title: 'Cadastro',
                icon: 'mdi mdi-gauge',
                class: 'has-arrow',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [
                    {
                        path: 'transportador',
                        title: 'Transportador',
                        icon: '',
                        class: 'mdi mdi-truck has-arrow',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: [
                            {
                                path: '/cadastro/transportador/cadastro/consulta',
                                title: 'Cadastro',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/transportador/revalidacao/consulta',
                                title: 'Reativação',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/transportador/ordinaria/consulta',
                                title: 'Revalidação Ordinária',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/transportador/alterar-dados/consulta',
                                title: 'Alteração de Dados',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/transportador/consultar',
                                title: 'Consultar',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/transportador/consulta-ordinaria',
                                title: 'Consultar Revalidação',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/transportador/verificar-pedido',
                                title: 'Verificar pedido ANTT',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            }
                        ]
                    },
                    {
                        path: 'veiculo',
                        title: 'Veículo',
                        icon: '',
                        class: 'mdi mdi-car has-arrow',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: [
                            {
                                path: '/cadastro/veiculo/movimentacao/consulta',
                                title: 'Movimentação',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/veiculo/exclusao/consulta',
                                title: 'Exclusão',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/veiculo/alterar-dados/consulta',
                                title: 'Alteração de Dados',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/veiculo/consultar',
                                title: 'Consultar',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/veiculo/historico',
                                title: 'Histórico',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/veiculo/cadastro-contrato-arrendamento',
                                title: 'Incluir contrato',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/cadastro/veiculo/lista-contrato-arrendamento',
                                title: 'Histórico contrato',
                                icon: '',
                                class: 'p-l-10',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            }
                        ]
                    }
                ]
            },
            {
                path: 'pedido',
                title: 'Pedido',
                icon: 'mdi mdi-apps',
                class: 'has-arrow',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [
                    {
                        path: '/pedido/consulta',
                        title: 'Acompanhamento',
                        icon: '',
                        class: 'mdi mdi-radar',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    }
                ]
            },
            {
                path: 'relatorio',
                title: 'Relatórios',
                icon: 'mdi mdi-bullseye',
                class: 'has-arrow',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [
                    {
                        path: '/relatorio/pedidos-pendentes/visualizar',
                        title: 'Pendências',
                        icon: '',
                        class: 'mdi mdi-pause-circle-outline',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/relatorio/pedidos-cancelados/visualizar',
                        title: 'Cancelados',
                        icon: '',
                        class: 'mdi mdi-close',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: 'repasse',
                        title: 'Repasses',
                        icon: '',
                        class: 'mdi mdi-contacts has-arrow',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: [
                            {
                                path: '/relatorio/repasse/sindicato/visualizar',
                                title: 'Sindicato',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/relatorio/repasse/federacao/visualizar',
                                title: 'Federação',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/relatorio/repasse/convenio/visualizar',
                                title: 'Convênio',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/relatorio/repasse/administracao/visualizar',
                                title: 'Administração',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            }
                        ]
                    }
                ]
            },
            {
                path: 'documentacao',
                title: 'Documentação',
                icon: 'mdi mdi-folder',
                class: 'has-arrow',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [
                    {
                        path: 'emissao',
                        title: 'Emissão',
                        icon: '',
                        class: 'mdi mdi-note-plus has-arrow',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: [
                            {
                                path: '/documentacao/emissao/extrato',
                                title: 'Extrato',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/documentacao/emissao/carteirinha',
                                title: 'Carteirinha',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/documentacao/emissao/certificado',
                                title: 'Certificado',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            }
                        ]
                    },
                    {
                        path: '/documentacao/upload',
                        title: 'Upload',
                        icon: '',
                        class: 'mdi mdi-upload',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    }
                ]
            },
            {
                path: 'admin',
                title: 'Administração',
                icon: 'mdi mdi-widgets',
                class: 'has-arrow',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: [
                    {
                        path: 'usuario',
                        title: 'Usuários',
                        icon: '',
                        class: 'mdi mdi-account-multiple has-arrow',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: [
                            {
                                path: '/admin/usuario/conectados',
                                title: 'Usuários Conectados',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/admin/usuario/gestao',
                                title: 'Gestão de Usuários',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/admin/usuario/log-acesso',
                                title: 'Logs de Acesso',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/admin/usuario/perfil',
                                title: 'Perfis de Acesso',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            }
                        ]
                    },
                    {
                        path: '/admin/taxas/consultar',
                        title: 'Taxas',
                        icon: '',
                        class: 'mdi mdi-currency-usd',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/admin/cnpj/lista',
                        title: 'Cadastro CNPJ',
                        icon: '',
                        class: 'mdi mdi-file-document-box',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/admin/historico-logs',
                        title: 'Histórico de logs',
                        icon: '',
                        class: 'mdi mdi-file-find',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path: '/admin/feed/lista',
                        title: 'Cadastro Feed de Notícas',
                        icon: '',
                        class: 'mdi mdi-rss',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    },
                    {
                        path:'./admin/financeiro',
                        title: 'Financeiro',
                        icon : '',
                        class: 'mdi mdi-currency-usd has-arrow',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: [
                            {
                                path: '/admin/financeiro/conciliacao-repasses',
                                title: 'Realizar conciliação',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/admin/financeiro/ted-repasses',
                                title: 'Gerar ted repasse',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            },
                            {
                                path: '/admin/financeiro/retorno-ted-repasses',
                                title: 'Retorno de ted',
                                icon: '',
                                class: '',
                                label: '',
                                labelClass: '',
                                extralink: false,
                                submenu: []
                            }
                        ]    
                    },
                    {
                        path: '/admin/entidade-creditos',
                        title: 'Aquisição de Créditos',
                        icon: '',
                        class: 'mdi mdi-currency-usd',
                        label: '',
                        labelClass: '',
                        extralink: false,
                        submenu: []
                    }
                ]
            }
        ];
    }

    public getRoutes(): RouteInfo[] {
        return this.routes;
    }
}
