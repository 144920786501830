export enum ColumnType {
    Hidden,
    Text,
    Date,
    FullDate,
    Action,
    Select,
    Enum,
    Boolean,
    Slider,
    Cnpj,
    Cpf,
    Document,
    Currency,
    Number,
}
