import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ModalService } from "./modal.service";
import { IsLoadingService } from "./is-loading.service";
import { URL_HELP_API } from "environments/environment";
import { Creditos } from "app/models/usuario/creditos";
import { PaginatedResponse, ResponseBase } from "app/interfaces/response-base";
import { SaldoCreditosModel } from "app/models/entidade-creditos/saldo-creditos.model";
import { ExtratoCreditosModel } from "app/models/entidade-creditos/extrato-creditos.model";
import { CreditoModel } from "app/models/entidade-creditos/creditos.model";
import { ValorFaturaModel } from "app/models/entidade-creditos/valor-fatura.model";
import { DetalheCreditosModel } from "app/models/entidade-creditos/detalhe-creditos.model";
import { catchError } from "rxjs/operators";
import { parseErrorBlob } from "app/shared/helpers/format/http-util";

@Injectable({
    providedIn: 'root'
})
export class EntidadeCreditosService extends BaseService<Creditos> {
    constructor(
        protected httpClient: HttpClient,
        protected modalService: ModalService,
        protected isLoadingService: IsLoadingService
    ) { super(httpClient, modalService, isLoadingService); }

    public obterSaldo(): Promise<ResponseBase<SaldoCreditosModel>> {
        return this.getBase(`${URL_HELP_API.ENTIDADE_CREDITOS.SALDO}`);
    }

    public obterDetalhes(): Promise<ResponseBase<DetalheCreditosModel>> {
        return this.getBase(`${URL_HELP_API.ENTIDADE_CREDITOS.DETALHE}`);
    }

    public comprarCreditos(request): Promise<ResponseBase<any>> {
        return this.postBase(`${URL_HELP_API.ENTIDADE_CREDITOS.COMPRAR}`, request);
    }

    public obterValorFatura(qtdCreditos): Promise<ResponseBase<ValorFaturaModel>> {
        return this.getBase(`${URL_HELP_API.ENTIDADE_CREDITOS.VALOR_FATURA}/${qtdCreditos}`);
    }

    public consultarHistoricoCreditosPaginated(uri: string): Promise<ResponseBase<PaginatedResponse<CreditoModel>>> {
        return this.getBase(`${URL_HELP_API.ENTIDADE_CREDITOS.HISTORICO}?${uri}`);
    }

    public consultarExtratoCreditosPaginated(uri: string): Promise<ResponseBase<PaginatedResponse<ExtratoCreditosModel>>> {
        return this.getBase(`${URL_HELP_API.ENTIDADE_CREDITOS.EXTRATO}?${uri}`);
    }

    public darBaixaBoleto(idCredito: string | number): Promise<ResponseBase<any>> {
        return this.patchBase(`${URL_HELP_API.ENTIDADE_CREDITOS.BASE}/creditos/${idCredito}/baixa-boleto`, null);
    }

    public extratoDownload(uri: string): Promise<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/vnd.ms-excel');

        return new Promise<any>((resolve, reject) => {
            this.httpClient.get(`${URL_HELP_API.ENTIDADE_CREDITOS.EXTRATO_DOWNLOAD}?${uri}`, { headers: headers, responseType: 'blob' })
            .pipe(catchError(parseErrorBlob))
            .subscribe(
                (res) => { resolve(res); },
                (err) => { this.error(err), reject(err); }
            );
        });
    }

    public historicoDownload(uri: string): Promise<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/vnd.ms-excel');

        return new Promise<any>((resolve, reject) => {
            this.httpClient.get(`${URL_HELP_API.ENTIDADE_CREDITOS.HISTORICO_DOWNLOAD}?${uri}`, {headers: headers, responseType: 'blob'})
            .pipe(catchError(parseErrorBlob))
            .subscribe(
                (res) => { resolve(res); },
                (err) => { this.error(err), reject(err); }
            );
        });
    }

    public usuarioAntecipacao(): Promise<ResponseBase<any>> {
        return this.getBase(`${URL_HELP_API.ENTIDADE_CREDITOS.ANTECIPACAO}`);
    }
}