import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[cpfCnpjMask]'
})
export class CpfCnpjMaskDirective {

    @Output()
    onValueChange = new EventEmitter<string>();

    @HostListener('input', ['$event'])
    inputEvent(event: KeyboardEvent) {
        this._eventHandler(event);
    }

    private _eventHandler(event) {
        const input = (event.target as HTMLInputElement);
        let value = input.value;

        value = value.replace(/[^0-9]+/g, '');
        if (value.length > 3) { value = value.substr(0, 3) + '.' + value.substr(3); }
        if (value.length > 7) { value = value.substr(0, 7) + '.' + value.substr(7); }
        if (value.length > 11) { value = value.substr(0, 11) + '-' + value.substr(11); }

        if (value.length > 14) {
            value = value.replace(/[^0-9]+/g, '');
            if (value.length > 2) { value = value.substr(0, 2) + '.' + value.substr(2); }
            if (value.length > 6) { value = value.substr(0, 6) + '.' + value.substr(6); }
            if (value.length > 10) { value = value.substr(0, 10) + '/' + value.substr(10); }
            if (value.length > 15) { value = value.substr(0, 15) + '-' + value.substr(15); }
            if (value.length > 17) { value = value.substr(0, 18) }
        }

        input.value = value;
        this.onValueChange.emit(value);
    }

}
