import { Directive, HostListener, OnDestroy, OnInit } from '@angular/core';
import {  NgModel } from '@angular/forms';
import { take, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[placaVeiculoMask]',
    providers: [NgModel]
})
export class PlacaVeiculoMaskDirective implements OnInit, OnDestroy {

    private _subscription = new  Subscription();

    constructor(private ngModel: NgModel) {}

    ngOnInit() {
        this._subscription = this.ngModel.valueChanges
            .pipe(take(1), filter(v => v !==  undefined))
            .subscribe({
                next: (modelValue:  any) => {
                    this.input(modelValue);
                }
            })
    }

    @HostListener('input', ['$event'])
    input(event) {
        const input = (event.target as HTMLInputElement);

        if (input) {
            let value = input.value;

            input.value = value
                .replace(/[^\w\s]/gi, '')
                .toUpperCase()
                .substr(0, 7);
        }
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

}
